{
  "countries": {
    "selectCountry": "Select country",
    "AF": "Afghanistan",
    "AX": "Åland Islands",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia (Plurinational State of)",
    "BQ": "Bonaire, Sint Eustatius and Saba",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "CV": "Cabo Verde",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo",
    "CD": "Congo (Democratic Republic of the)",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "CI": "Côte d'Ivoire",
    "HR": "Croatia",
    "CU": "Cuba",
    "CW": "Curaçao",
    "CY": "Cyprus",
    "CZ": "Czechia",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (Malvinas)",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island and McDonald Islands",
    "VA": "Holy See",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran (Islamic Republic of)",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea (Democratic People's Republic of)",
    "KR": "Korea (Republic of)",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People's Democratic Republic",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MK": "Macedonia (the former Yugoslav Republic of)",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia (Federated States of)",
    "MD": "Moldova (Republic of)",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestine, State of",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Réunion",
    "RO": "Romania",
    "RU": "Russian Federation",
    "RW": "Rwanda",
    "BL": "Saint Barthélemy",
    "SH": "Saint Helena, Ascension and Tristan da Cunha",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint Martin (French part)",
    "PM": "Saint Pierre and Miquelon",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome and Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten (Dutch part)",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "SS": "South Sudan",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard and Jan Mayen",
    "SZ": "Swaziland",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan, Province of China",
    "TJ": "Tajikistan",
    "TZ": "Tanzania, United Republic of",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom of Great Britain and Northern Ireland",
    "US": "United States of America",
    "UM": "United States Minor Outlying Islands",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela (Bolivarian Republic of)",
    "VN": "Viet Nam",
    "VG": "Virgin Islands (British)",
    "VI": "Virgin Islands (U.S.)",
    "WF": "Wallis and Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
  },
  "placeholders": {
    "dateTime": "tt-mm-jjjj hh:mm",
    "date": "tt-mm-jjjj",
    "time": "hh:mm",
    "search": "Suche...",
    "searchCountryCode": "Search for country code",
    "loading": "Wird geladen...",
    "email": "muster{'@'}mail.com",
    "uploadImagePlaceholder": "Bild auswählen oder hier ablegen.",
    "dropImagePlaceholder": "Ziehe dein Bild hierhin.",
    "selectOrganisationMessage": "Organisation auswählen",
    "selectGate": "Gate suchen oder auswählen...",
    "selectRole": "Rolle auswählen",
    "selectLocale": "Sprache auswählen",
    "selectTicketeer": "Ticketeer auswählen",
    "phoneNumber": "e.g. +141566573212"
  },
  "intervals": {
    "hour": "Pro Stunde",
    "day": "Pro Tag",
    "week": "Pro Woche",
    "month": "Pro Monat",
    "year": "Pro Jahr"
  },
  "permissions": {
    "noPermissions": "Du hast nicht die notwendigen Berechtigungen",
    "contactAdministrator": "Kontaktiere den Administrator für notwendige Berechtigungen",
    "insufficientPermissions": "Unzureichende Berechtigungen"
  },
  "notifications": {
    "saveSuccess": "Erfolgreich gespeichert",
    "savePublishSuccess": "Erfolgreich gespeichert und veröffentlicht",
    "removeSuccess": "Erfolgreich entfernt",
    "saveFail": "Speichern fehlgeschlagen",
    "removeFail": "Entfernen fehlgeschlagen",
    "cashierCartExpired": "Your cart has expired due to inactivity. Please reload the page to start over.",
    "cartUpdate": {
      "edit": {
        "success": "The cart has been updated successfully.",
        "fail": "Failed to update the cart."
      },
      "add": {
        "success": "The ticket(s) have been added successfully.",
        "fail": "Failed to add ticket(s)."
      },
      "remove": {
        "success": "The ticket(s) have been removed successfully.",
        "fail": "Failed to remove ticket(s)."
      }
    },
    "genericError": "Fehler",
    "successUpdate": "Erfolgreich aktualisiert",
    "successEmail": "E-Mail versendet",
    "successShared": "Ticket geteilt",
    "successDownload": "Datei heruntergeladen",
    "successPassword": "Passwort wurde erfolgreich zurückgesetzt",
    "copiedToClipboard": "In die Zwischenablage kopiert!",
    "copyFailed": "Failed to copy to your clipboard",
    "invitationSent": "Einladung gesendet",
    "notEnoughPrivs": "Fehler: Diese Anzahl von Tickets kann nicht blockiert werden, da nicht (mehr) viele verfügbar sind. Du kannst versuchen, die Anzahl der Tickets zu erhöhen.",
    "unSavedAlert": {
      "title": "Du hast ungespeicherte Änderungen",
      "description": "Bist du sicher, dass du die Seite verlassen möchtest, ohne deine Änderungen zu speichern?"
    },
    "TicketKindExists": "Du kannst diesen Pool nicht entfernen, da ihm zugeordnete Tickets zugeordnet sind",
    "TicketKindExistsInShop": "Du kannst diesen Shop nicht entfernen, da mit ihm verknüpfte Tickets verknüpft sind",
    "anonymizeUserSuccess": "The account data has been anonymized.",
    "anonymizeUserFail": "An error occurred while removing the account, the user is probably in the process of purchasing a ticket. Please try again later.",
    "feature_flag": "This feature is available for users with 'Tester' permissions only.",
    "tasks": {
      "taskLabel": "Aufgabe:",
      "stateLabel": "Bundesland:",
      "states": {
        "processing": "In Bearbeitung",
        "not_started": "Noch nicht begonnen",
        "failed": "Fehler",
        "completed": "Abgeschlossen"
      },
      "event_copy": {
        "title": "Event kopieren",
        "taskDescription": "Kopiere {title}",
        "link": "Gehe zum neuen Event"
      },
      "shop_copy": {
        "title": "Shop kopieren",
        "taskDescription": "Kopiere Shop {title}"
      },
      "template_generate": {
        "title": "Veranstaltung erstellen",
        "taskDescription": "Aus Vorlage erstellen:",
        "link": "Gehe zur neuen Veranstaltung"
      },
      "template_copy": {
        "title": "Copy template",
        "taskDescription": "Copying Template {title}"
      }
    }
  },
  "navigation": {
    "home": "Zuhause",
    "events": "Event",
    "customers": "Customers",
    "upcoming": "Bevorstehend",
    "past": "Vorher",
    "draft": "Entwurf",
    "reports": "Berichte",
    "checkIns": "Check-ins",
    "sales": "Verkäufe",
    "exports": "Exporte",
    "manage": "Verwalten",
    "blocks": "Blöcke",
    "general": "Allgemein",
    "eventMarketing": "Marketing",
    "eventAdvanced": "Erweitert",
    "eventBlockchain": "Blockchain",
    "resaleFees": "Resale fees",
    "scanning": "Scannen",
    "seating": "Sitzplätze",
    "external": "Externe",
    "demographics": "Demographie",
    "pools": "Pools",
    "shops": "Läden",
    "prices": "Preise",
    "gates": "Gates",
    "templates": "Vorlagen",
    "schedules": "Zeitpläne",
    "sync": "Sync-Änderungen",
    "organizations": "Organisationen",
    "checkin": "Check-in",
    "orders": "Bestellungen",
    "reservations": "Reservations"
  },
  "actions": {
    "accept": "Akzeptieren",
    "seeAll": "Alle anzeigen",
    "ok": "Ok",
    "close": "Schließen",
    "logout": "Abmelden",
    "login": "Anmelden",
    "signup": "Registrieren",
    "publish": "Veröffentlichen",
    "savePublish": "Speichern und veröffentlichen",
    "confirm": "Bestätigen",
    "confirmAll": "Confirm all",
    "saveChanges": "Änderungen speichern",
    "invalidate": "Entwerten",
    "reset": "Reset",
    "export": "Exportieren",
    "loadMore": "Mehr laden",
    "cancel": "Abbrechen",
    "search": "Suchen",
    "copy": "Kopieren",
    "apply": "Anwenden",
    "edit": "Bearbeiten",
    "back": "Zurück",
    "save": "Speichern",
    "delete": "Löschen",
    "preview": "Vorschau",
    "next": "Nächste",
    "uploadImage": "Bild hochladen",
    "checkin": "Check-in",
    "showAll": "Alle anzeigen",
    "clear": "Leeren",
    "solve": "Autoseat",
    "finalize": "Abschließen",
    "assign": "Zuweisen",
    "printTickets": "Ticket drucken | Tickets drucken",
    "printPrivileges": "Print privilege | Print privileges",
    "printAllPrivilegesforCustomer": "Print all tickets",
    "print": "Print",
    "block": "Blockieren",
    "unblock": "Freigabe",
    "addRow": "Zeile hinzufügen",
    "email": "E-Mail",
    "sendEmail": "Send Email",
    "resend": "Resend",
    "revoke": "Revoke",
    "editPhoneNumber": "Edit Phone Number",
    "deleteAccount": "Delete Account",
    "sellTickets": "Sell Tickets",
    "batchAction": "Batch-Aktion",
    "import": "Importieren",
    "browse": "Durchsuchen",
    "selectTimezone": "Bitte eine Zeitzone wählen",
    "chooseFile": "Datei auswählen oder hier hinein ziehen",
    "linkEmbed": "Link/Einbetten",
    "view": "Ansicht",
    "click": "hier klicken",
    "checkout": "Check-in rückgängig",
    "add": "Hinzufügen",
    "remove": "Entfernen",
    "removeAll": "Remove all",
    "change": "Änderungen",
    "activate": "Aktivieren",
    "deactivate": "Deaktivieren",
    "toDashboard": "Zum Dashboard wechseln",
    "switchOrganization": "Organisation wechseln",
    "allOrganizations": "Alle meine Organisationen anzeigen",
    "goToEvents": "Zu meinen Events",
    "clearFile": "Datei löschen",
    "download": "Herunterladen",
    "help": "Hilfe",
    "multiNoteTicket": "Multiple notes. Click the ticket for details.",
    "multiSeatingTicket": "Multiple seatings. Click the ticket for details.",
    "createGroup": "Gruppe erstellen",
    "select": "Select",
    "issue": "Issue",
    "issueNow": "Issue now",
    "createReservation": "Create Reservation",
    "update": "Update",
    "distributeTickets": "Distribute tickets",
    "sendNotificationTo": "Send notification to {name}"
  },
  "labels": {
    "en": "English",
    "nl": "Nederlands",
    "de": "German",
    "it": "Italiano",
    "ko": "한국어",
    "es": "Spanisch",
    "locale": "Sprache",
    "yes": "Yes",
    "no": "No",
    "name": "Name",
    "shortName": "Short name",
    "date": "Datum",
    "time": "Zeit",
    "id": "ID",
    "checkinOptions": {
      "all": "All",
      "blocked": "Blockiert",
      "assigned": "Zugewiesen"
    },
    "amountSpecifier": "of",
    "ticketeer": "Ticketeer",
    "currentOrg": "Aktuelle Organisation",
    "events": "Ereignis | Events",
    "eventTitle": "Ereignisname | Ereignisname",
    "order": "Order | Orders",
    "reservation": "Reservation",
    "created": "Erstellt",
    "expiry": "Expiry",
    "expired": "Expired",
    "claimed": "Claimed",
    "open": "Open",
    "canceled": "Canceled",
    "totalValue": "Total Value",
    "paidValue": "Paid value",
    "completed": "Completed",
    "actions": "Actions",
    "payment": "Payment | Payments",
    "method": "Method | Methods",
    "orderId": "Order-ID {id}",
    "paymentId": "Payment-ID {id}",
    "template": "Vorlage | Vorlagen",
    "templateTitle": "Vorlagenname",
    "organization": "Organisation",
    "section": "Abschnitt",
    "enabled": "aktiviert",
    "disabled": "deaktiviert",
    "state": "State",
    "success": "Erfolgreich!",
    "danger": "Fehler",
    "credits": "Credits",
    "summary": "Zusammenfassung",
    "paymentMethod": "Payment Method | Payment methods",
    "pspId": "Psp ID",
    "type": "Type",
    "left": "Left",
    "max": "Max",
    "label": "{options} and {length} more",
    "notAvailableShort": "N/A",
    "availability": "Verfügbarkeit",
    "privilege": "Privilege | Privileges",
    "category": "Category | Categories",
    "yourOrders": "Your orders",
    "yourCustomers": "Your customers",
    "yourShops": "Your shops",
    "contactName": "Contact name",
    "contactEmail": "Contact email",
    "contactPhone": "Contact phone",
    "email": "Email address",
    "createdOn": "Created on",
    "createdBy": "Created by",
    "yourReservations": "Your reservations",
    "reservationId": "Reservation-ID {id}",
    "invitations": "Invitation | Invitations",
    "recipient": "Recipient",
    "claimedBy": "Claimed by",
    "dates": {
      "today": "Heute",
      "yesterday": "Gestern",
      "last7Days": "Letzte 7 Tage",
      "last4Weeks": "Letzte 4 Wochen",
      "thisMonth": "Diesen Monat",
      "thisYear": "Dieses Jahr",
      "custom": "Eigene",
      "allTime": "Gesamt",
      "endDate": "Enddatum",
      "startDate": "Startdatum",
      "fromDate": "Ab Datum",
      "untilDate": "Bis Datum (inklusive)",
      "years": "Jahr | Jahre",
      "am": "AM",
      "pm": "PM",
      "timezone": "Time zone",
      "validFrom": "Valid from",
      "validUntil": "Valid until",
      "dateOptions": {
        "dateAndTime": "Datum & Zeit",
        "specificDate": "Bestimmter Tag und Uhrzeit",
        "whenShopOpens": "when the shop opens",
        "relativeDateEnds": "on relative time before the event ends",
        "relativeDateStarts": " on relative time before the event starts",
        "whenEventEnds": "Wenn das Event endet",
        "whenDoorsOpen": "when the doors open",
        "doorsOpenWarning": "Your event starts on {eventDateTime}. The customers' tickets QR code will become available in their app 3 hours before the doors open.",
        "immediately": "Sofort",
        "absoluteTimeWarning": "Warning: Created events from a template, or copied events, will use this specific date, even when the newly created event's date is different.",
        "relativeTimeWarningStart": {
          "cta": "Enter the amount of days before the event starts and the time on that day to use this relative date.",
          "warning": "If your event starts on {eventStarts} the actual date would be the {selectedDateTime}"
        },
        "relativeTimeWarningEnd": {
          "cta": "Gib die Anzahl der Tage vor dem Ende des Events und die Zeit an diesem Tag ein, um dieses relative Datum zu verwenden.",
          "warning": "If your event ends on {eventEnds} the actual date would be the {selectedDateTime}"
        },
        "relativeTimeInfo": "Gib die Anzahl der Tage vor dem Ende des Events und die Zeit an diesem Tag ein, um dieses relative Datum zu verwenden.",
        "relativeTimeExample": "Wenn dein Event am {eventEnds} endet, würde das eigentliche Datum am {datetime} sein.",
        "ticketRelativeTimeFromInfo": "Enter the amount of days before the event ends and the time on that day to set the moment when these tickets become available at the shop.",
        "ticketRelativeTimeToInfo": "Enter the amount of days before the event ends and the time on that day to set the moment when these tickets become unavailable at the shop.",
        "daysBeforeEventEnds": "Tage vor dem Ende des Events",
        "daysBeforeEventStarts": "Days before the event starts",
        "dayTime": "Zeit an diesem Tag",
        "never": "Never"
      }
    },
    "profile": {
      "anonymous": "Anonymous",
      "firstName": "Vorname",
      "lastName": "Nachname",
      "phoneNumber": "Telefonnummer",
      "mobileNumber": "Mobile number",
      "email": "E-Mail",
      "language": "Sprache",
      "confirmEmail": "E-Mail bestätigen",
      "zipcode": "Postleitzahl",
      "birthdate": "Geburtsdatum",
      "gender": "Geschlecht",
      "ageGroups": "Altersgruppen",
      "role": "Rolle",
      "m": "Männlich",
      "f": "Weiblich",
      "o": "Andere",
      "lastPurchase": "Letzter Kauf",
      "contact": "Kontaktinformationen",
      "addressInformation": "Adressinformationen",
      "basicInformation": "Grundlegende Informationen",
      "address": "Straßenname & Hausnummer",
      "addressShort": "Adresse",
      "city": "Stadt",
      "country": "Land",
      "state": "State",
      "nft_wallet_address": "NFT wallet address",
      "gusuuid": "GUS uuid",
      "publicTicketEngineUrl": "Wallet link"
    },
    "event": {
      "shortName": "Short name",
      "seated": "Sitzplatz",
      "nonSeated": "Allgemeine Zulassung",
      "livestream": "Livestream",
      "title": "Titel",
      "subtitle": "Untertitel",
      "location": "Standort",
      "sublocation": "Unterordner (Halle)",
      "city": "Stadt",
      "country": "Land",
      "address": "Adresse",
      "datesTitle": "Event & Zeiten",
      "doorsOpen": "Öffnungszeit",
      "startDate": "Datum des Event",
      "daysRunning": "Dauer in Tagen",
      "when": "Startzeit",
      "ends": "Endzeit am letzten Tag",
      "endDateTime": "Enddatum und -zeit",
      "saleStart": "Verkaufs-Startdatum",
      "saleEnds": "Verkaufs-Enddatum",
      "url": "URL des Event",
      "slug": "Slug",
      "draft": "Entwurf",
      "timezone": "Zeitzone, in der das Ereignis stattfindet",
      "capacity": "Kapazität"
    },
    "ticketStatus": {
      "sold": "Verkauft",
      "returned": "Zurückgegeben",
      "resold": "Weiterverkauft",
      "forsale": "Zum Verkauf",
      "being-resold": "Zum Verkauf und im Warenkorb",
      "created": "Erstellt",
      "invalidated": "Ungültig",
      "executed": "Eingecheckt",
      "partialCheckedIn": "Checked-in",
      "inCart": "Im Warenkorb",
      "notForSale": "Nicht zum Verkauf",
      "notExecuted": "Nicht eingecheckt",
      "unassigned": "Nicht zugeordnet",
      "blocked": "Blockiert",
      "blocks": "Blöcke",
      "assigned": "Zugewiesen",
      "external": "Externe",
      "free": "Verfuegbar",
      "released": "Released",
      "transferred": "Reservation Claimed"
    },
    "orderStatus": {
      "all": "All",
      "created": "Created",
      "pending": "Pending",
      "canceled": "Canceled",
      "timeout": "Timeout",
      "completed": "Completed",
      "failed": "Failed"
    },
    "reservationStatus": {
      "label": "Status",
      "all": "All",
      "confirmed": "Confirmed",
      "distribution_started": "Distribution started",
      "awaiting_confirmation": "Awaiting confirmation",
      "awaiting_distribution": "Awaiting distribution",
      "invalidated": "Invalidated",
      "checked_in": "Checked-in",
      "partially_checked_in": "Checked-in",
      "invited": "Invited",
      "expired": "Expired",
      "canceled": "Canceled",
      "completed": "Completed"
    },
    "reservationInvitationStatus": {
      "label": "Status",
      "invited": "Invited",
      "claimed": "Accepted",
      "revoked": "Revoked",
      "created": "Created"
    },
    "paymentStatus": {
      "pending": "Pending",
      "paid": "Paid",
      "duplicated": "Duplicated",
      "expired": "Expired",
      "failed": "Failed",
      "canceled": "Canceled"
    },
    "refundStatus": {
      "pending": "Pending",
      "success": "Success",
      "failed": "Failed"
    },
    "orderType": {
      "normal": "Online",
      "cashier": "Cashier"
    },
    "seat": {
      "rows": "Zeile | Zeilen",
      "rowsAbbreviation": "Rw",
      "sections": "Abschnitt | Abschnitte",
      "sectionsAbbreviation": "Sec",
      "subsections": "Unterabschnitt | Unterabschnitte",
      "subsectionsAbbreviation": "Sub-s",
      "entrances": "Eingang | Eingänge",
      "entrancesAbbreviation": "Ent",
      "ranks": "Rang | Rang | Rang",
      "tiers": "Stufe | Ebenen",
      "seats": "Sitz | Plätze",
      "seatsAbbreviation": "St",
      "category": "Kategorie",
      "cashierSeating": {
        "auto": "Seated automatically",
        "manual": "Seated manually",
        "not_applicable": "Not applicable"
      }
    },
    "ticketTypes": {
      "upsell": "Upsell",
      "access": "Entrance"
    },
    "ticketType": "Ticket",
    "eventOrganizer": "Veranstalter",
    "tickets": "Ticket | Tickets",
    "types": "Type | Types",
    "totals": "Gesamt | Summen",
    "total": "Gesamt",
    "from": "Von",
    "until": "Bis",
    "description": "Beschreibung",
    "available": "Verfügbar",
    "forSale": "Zum Verkauf",
    "locks": "Block | Blöcke",
    "guests": "Gast | Gäste",
    "upsells": "Upsell | Up-Sells",
    "variants": "Variante | Varianten",
    "combiTicket": "Combi-Ticket",
    "guestlist": "Gastliste",
    "price": "Preis",
    "free": "Kostenlos",
    "status": "Status",
    "lastStatus": "Last status",
    "onDate": "on {date}",
    "groups": "Gruppe | Gruppen",
    "notes": "Notiz | Notizen",
    "sales": "Verkäufe",
    "checkIns": "Check-ins",
    "seating": "Sitzplatz",
    "privileges": "Privileges",
    "accessPrivileges": "Entrance Privilege | Entrance Privileges",
    "ownedBy": "Owned by",
    "noResults": {
      "default": "No results found...",
      "orders": "No orders found...",
      "customers": "No customers found..."
    },
    "results": "results",
    "step": "Schritt {count}",
    "amount": "Betrag",
    "amountFinance": "Amount",
    "amountRefunded": "Amount refunded to {currency}",
    "pricePerTicket": "Price per ticket",
    "numberOfTickets": "Number of tickets",
    "subtotal": "Subtotal",
    "paidInFiat": "Paid in {currency}",
    "paidWithCredits": "Bezahlt mit Credits",
    "refundedToCredits": "Erstattet mit Credits",
    "dateCreated": "Date created",
    "dateUpdated": "Last updated",
    "totalDeducted": "Total deducted",
    "referral_id": "Referral ID",
    "primary": "Primary",
    "secondary": "Secondary",
    "unknown": "Unknown",
    "note": "Notiz",
    "optional": "(optional)",
    "mandatory": "(mandatory)",
    "localTime": "Lokale Zeit",
    "other": "Andere",
    "pools": "Pool | Pools",
    "availableTicketsTitle": "Available tickets",
    "availableTickets": "Available",
    "allocated": "Zugewiesen",
    "blocks": "Blöcke",
    "refundType": {
      "name": "Refund method",
      "auto": "Automatic",
      "manual": "Manual",
      "credits": "Credits",
      "no_refund": "No refund"
    },
    "shops": "Läden",
    "details": "Details",
    "priceType": "Preistyp",
    "priceSelect": "Preis auswählen",
    "customPrice": "Eigener Preis",
    "globalPrice": "Globaler Preis",
    "totalPrice": "Total price",
    "example": "(Beispiel)",
    "users": "Benutzer",
    "currency": "Währung",
    "paidEur": "Bezahlt mit {currency}",
    "paidCredits": "Bezahlt mit Credits",
    "refundedEur": "Erstattet zu {currency}",
    "refundedCredits": "Erstattet an Credits",
    "grossRevenue": "Brutto-Einnahmen",
    "refunded": "Erstattet",
    "inCurrency": "In {currency}",
    "toCredits": "Zu Credits",
    "market": "Market",
    "info": "Info",
    "paidWithCreditsPartially": "Partially paid with credits",
    "mandatoryField": "mandatory field",
    "collectibles": "Collectibles"
  },
  "validation": {
    "error": {
      "minFileSize": "Die Dateigröße muss größer als {max}Kb sein.",
      "minValue": "Wert sollte größer als {min} sein",
      "maxValue": "Wert sollte kleiner als {max} sein",
      "maxDecimals": "You can use up to {precision} decimals",
      "validateImageDeminsons": "Die Auflösung muss höher sein als {width}x{height}",
      "minDate": "{field} muss nach {minVal} liegen",
      "maxDate": "Die {field} -Zeit muss vor {maxVal} liegen",
      "minOffset": "{field} muss vor {maxVal} liegen",
      "maxOffset": "{field} muss nach {minVal} liegen",
      "required": "Dieses Feld darf nicht leer sein",
      "richTextMaxLength": "Es darf nicht mehr als {maxLength} Zeichen lang sein",
      "cover": "Dieses Bild hat nicht die richtige Größe",
      "maxSize": "Es sollte {maxSize} kb nicht überschreiten",
      "fileType": "Es ist nicht der richtige Dateityp",
      "maxLength": "Feld darf nicht länger als {max} sein",
      "maxCharLength": "Feld darf nicht länger als {max} Zeichen sein",
      "combinedMaxLength": "Jedes Feld darf nicht länger als {max} Zeichen sein",
      "decimal": "Es sollte eine gültige Zahl sein",
      "alphaNum": "Es sollte ein gültiger alphanumerischer Wert sein",
      "alphaNumDashUnderscore": "Es sollte nur alphanumerische, unterstriche und Bindestriche enthalten",
      "numeric": "Es sollte ein numerischer Wert sein",
      "phoneNumber": "Dies ist keine gültige Mobilfunknummer. (Ländercodes sind erforderlich, d.h. \"+31\")",
      "sameAsNumber": "Die Telefonnummern stimmen nicht überein, bitte erneut eingeben.",
      "sameAs": "Die eingegebenen {field} stimmen nicht überein, bitte gib sie erneut ein.",
      "url": "Bitte gebe eine gültige URL ein (https://example.com).",
      "email": "Please enter a valid email address",
      "integer": "Please enter a whole number",
      "minLength": "Das {field} muss mindestens {length} Zeichen lang sein.",
      "nonNumeric": "Das {field} kann nicht nur numerische Zeichen enthalten.",
      "wrongPass": "Bitte gib das richtige Passwort ein.",
      "phoneNumberValidation": "Always include plus sign (+) and country code!",
      "isNotUnicode": "Please make sure to use no unicode characters"
    }
  },
  "views": {
    "reports": {
      "title": "Deine Exporte",
      "nav": {
        "exports": "Exporte",
        "sales": "Verkäufe"
      },
      "exports": {
        "tabs": {
          "revenue": "Umsatz",
          "summary": "Zusammenfassung",
          "orders": "Bestellungen",
          "attendees": "Teilnehmer",
          "blocks": "Blöcke",
          "advanced": "Erweitert",
          "groups": "Gruppen"
        },
        "type": {
          "group_sizes_report": "Gruppen",
          "revenue_report": "Umsatz",
          "summary_report": "Zusammenfassung",
          "orders_report": "Bestellungen",
          "attendees_report": "Teilnehmer",
          "blocks_report": "Blöcke"
        },
        "exportHistory": {
          "title": "Deine letzten Exporte",
          "created": "Erstellt {date}",
          "generating": "Export wird erstellt...",
          "exportGenerationSuccess": "Export erfolgreich abgeschlossen"
        },
        "selectEventsAction": "Veranstaltung hinzufügen oder entfernen",
        "action": "Neuen Export generieren",
        "reportsGenerationFail": "Erstellen des Exports fehlgeschlagen, bitte erneut versuchen...",
        "configurationError": "Keine zu Ihrer Organisation gehörenden Veranstaltungen im ausgewählten Gate gefunden...",
        "limitError": "You can generate a report for a maximum of {max} events! In case you want to create bigger exports, please contact our support.",
        "selectedEvents": "{selectedEvents} Veranstaltung ausgewählt | {selectedEvents} Veranstaltung ausgewählt",
        "gateInfo": "Hinweis: Nur Veranstaltungen, die zu Ihrer Organisation gehören, werden in den Export aufgenommen.",
        "selectOrg": "Organisation zum Erstellen des Exports auswählen",
        "options": {
          "tickets": "Optionen: Tickets & Up-Sells",
          "events": "Optionen: für Veranstaltungen & Shops"
        },
        "advanced": {
          "eventSales": "Alle Verkaufstransaktionen",
          "secondaryMarket": "Wiederverkaufsmarkt-Transaktionen",
          "billingSummary": "Abrechnungszusammenfassung"
        },
        "attendees": {
          "privacy-policy": "Teilnehmer haben den Datenschutzrichtlinien zugestimmt (falls zutreffend)",
          "user-privacy": "Nur Teilnehmer, die Ihre Datenschutzerklärung akzeptiert haben",
          "brand-privacy": "Nur Teilnehmer, die die Datenschutzrichtlinie von {brand} akzeptiert haben",
          "all": "Alle Teilnehmer einbeziehen",
          "optional": "Optionale Teilnehmer einbeziehen",
          "non-owners": "Teilnehmer, die keine Tickets mehr besitzen (verkauft, ungültig)",
          "invited": "Teilnehmer, die von Ticketinhabern eingeladen wurden, in eine Gruppe einbeziehen",
          "warning": "Hinweis: Nach europäischem Recht darfst Du die Daten von Teilnehmern, die deinen Datenschutzbestimmungen nicht zugestimmt haben, nur für Servicezwecke verwenden. Dies schließt Marketing aus."
        },
        "merge": {
          "pricetype": "Preis hinzufügen",
          "hideItems": "Elemente mit leeren Werten ausblenden"
        },
        "groupBy": {
          "events": "Nach Event sortieren",
          "shop": "Nach Shop sortieren"
        },
        "desiredFormat": "Gewünschtes Format",
        "format": {
          "xlsx": "Excel (xlsx)",
          "csv": "Durch Komma getrennt (csv)"
        },
        "includeEvents": "Folgende Events einbeziehen",
        "eventOptions": {
          "all-events": "Alle Events",
          "specific-gate": "Events in bestimmten Gates",
          "specific-events": "Wähle bestimmte Veranstaltungen"
        },
        "periodInclude": "Wähle den Zeitraum, in dem die Einnahmen eingehen",
        "periodOptions": {
          "specific-period": "Zeitraum festlegen",
          "all-time": "Gesamt"
        },
        "selectEventsModal": {
          "title": "Veranstaltungen für den Export auswählen",
          "yourEvents": "Deine Events",
          "added": "Event hinzugefügt",
          "placeholder": "Events suchen",
          "addEvent": "{count} Event hinzufügen | {count} Event hinzufügen",
          "removeEvent": "Entferne {count} Event | Entferne {count} Events",
          "info": "Um mehrere Veranstaltungen auszuwählen, kannst Du die Umschalt- oder Steuerungstaste (Befehlstaste auf dem Mac) drücken und auswählen oder klicken und ziehen."
        }
      }
    },
    "organizations": {
      "organization": {
        "title": "Organisation",
        "name": "Organisation Identity",
        "legalName": "Rechtlicher Name (optional)",
        "legalNameDescription": "Der offizielle rechtliche Name Ihrer Organisation",
        "privacyPolicy": "Text und/oder Datei der Datenschutzrichtlinie (optional)",
        "privacyPolicyFileDescription": "Überschreibe den Standardtext für dein Datenschutz-Opt-in",
        "privacyPolicyPlaceholder": "Zum Beispiel: Ich möchte über Veranstaltungen und Werbeaktionen informiert werden und stimme der Datenschutzerklärung von {orgName} zu.",
        "termsConditions": "Text und/oder Datei der Allgemeinen Geschäftsbedingungen (optional)",
        "termsConditionsFileDescription": "z.B. Überschreibe den Standardtext für deine Allgemeinen Geschäftsbedingungen Opt-in",
        "termsConditionsPlaceHolder": "Ich akzeptiere die allgemeinen Geschäftsbedingungen von {orgName}.",
        "localeSelectPlaceholder": "Select default language for e-mails etc.",
        "pspSelectPlaceholder": "Select a payment service provider.",
        "demo": {
          "label": "Demo mode",
          "description": "Create organisation for demo purposes only",
          "info": "A demo organisation is limited to dummy payments, and cannot be used to sell real tickets.",
          "badge": "Demo",
          "alert": "This organisation is set to demo mode. Real payments are disabled. Events created under it are only to be used for demo or testing purposes."
        },
        "miscellaneous": {
          "title": "Sonstiges",
          "refund": "Erstattung auf Guthaben",
          "expiration": "Verfallsdatum",
          "expirationInfo": "Dieser Zeitraum kann je nach Rolle überschrieben werden."
        },
        "paymentInfo": {
          "title": "Zahlungen",
          "psp": {
            "label": "Zahlungsdienstleister",
            "dummyInfo": "Hinweis: Nur für Demos oder temporäre Tests von unveröffentlichten Veranstaltungen verwenden.",
            "selectInfo": "Note: The selected currency determines which payment service providers are available.",
            "lockedInfo": "Hinweis: Du kannst den Zahlungsdienstleister nicht mehr wechseln, nachdem dein erstes Ticket verkauft wurde.",
            "options": {
              "mollie": "Mollie",
              "stripe": "Stripe",
              "dummy": "Demo",
              "ticketeer_payments": "Zahlungsdienstleister {brand}"
            }
          },
          "apiKey": {
            "test_key": "You don't have permission to submit TEST API credentials",
            "label_apiKey_mollie": "Live API key",
            "label_apiKey_stripe": "Secret Key",
            "label_publicKey_stripe": "Publishable key",
            "label": "API key",
            "publicKey": "Public API key",
            "addInfo": "Füge den API-Schlüssel hinzu, der von deinem Zahlungsdienstleister zur Verfügung gestellt wird. Wenn Du den API-Schlüssel korrekt einstellst, erfolgt die Genehmigung sofort.",
            "existsInfo": "Hinweis: Du kannst diesen API-Schlüssel nicht ändern, nachdem dein erstes Ticket verkauft wurde.",
            "error": "Dieser API-Schlüssel scheint nicht gültig zu sein",
            "apiErrors": {
              "invalid_api_key": "Fehler: Ungültiger API-Schlüssel"
            }
          },
          "methods": {
            "title": "Zahlungsmethoden",
            "overrideLabel": "Zahlungsarten aufheben",
            "overrideInfo": "Die folgenden Zahlungsmethoden werden entsprechend dem von Dir gewählten Zahlungsdienst-Konto eingestellt. Wenn Du Dich entschieden hast, die Zahlungsmethoden außer Kraft zu setzen, werden Änderungen in Deinem Zahlungsdienst-Konto nicht in diesen Einstellungen berücksichtigt.",
            "overrideInfoStripe": "Important: The accepted payment methods need to exactly match your Stripe enabled payment methods, otherwise payments will not work! By default everything is enabled."
          }
        },
        "fileDescription": "Nur PDFs mit einer maximalen Dateigröße von 20mb",
        "previewFileLink": "Datei anzeigen",
        "usersAndRoles": {
          "navTitle": "Benutzer & Rollen",
          "create": "Benutzer einladen",
          "active": "Aktiv",
          "deactivated": "Deaktiviert",
          "search": "Benutzer suchen",
          "activateUser": "Aktivieren",
          "pendingInvitation": "{role} ausstehende Einladung",
          "expiredInvitation": "Einladung abgelaufen",
          "inviteUserModal": {
            "edit": "Benutzer bearbeiten",
            "pending": "Ausstehende Einladung",
            "expired": "Abgelaufene Einladung",
            "invite": "Benutzer einladen",
            "info": "An diese E-Mail-Adresse wird eine E-Mail geschickt, um ein Konto zu erstellen oder sich anzumelden und die Organisation mit diesem Benutzer zu verknüpfen.",
            "emailAlreadyUsed": "Diese E-Mail-Adresse wurde bereits zum Dashboard eingeladen. Bitte verwende eine andere E-Mail-Adresse.",
            "send": "Einladungen versenden",
            "resend": "Erneut einladen",
            "removeInvitation": "Einladung zurücknehmen",
            "deactivate": "Deaktivieren"
          },
          "switchUserStateModal": {
            "activateTitle": "Benutzer aktivieren",
            "deactivateTitle": "Benutzer deaktivieren",
            "description": "Bist du sicher, dass du {action} {email} möchtest?"
          }
        },
        "attendeeInfo": {
          "title": "Teilnehmerinformationen",
          "description": "Im Folgenden findest Du die Informationen, die die Teilnehmer beim Kauf von Eintrittskarten für Deine Veranstaltungen ausfüllen müssen. Du kannst auswählen, ob die nachstehenden Informationen als Pflichtfelder, optionale Felder oder ausgeblendet gelten sollen. Die als Pflichtfelder gekennzeichneten Felder können nicht bearbeitet werden.",
          "states": {
            "always": "Immer verpflichtend",
            "mandatory": "Pflicht",
            "optional": "Optional",
            "hide": "Ausblenden"
          },
          "anonymize": {
            "button": "Remove account",
            "modal": {
              "title": "Are you sure?",
              "description": "Removing the account will result in the deletion of all data and tickets. It CANNOT be rolled back."
            }
          }
        },
        "floorplans": "Sitzplan"
      },
      "placeholder": "Organisationen suchen",
      "create": "Organisation erstellen",
      "modal": {
        "title": "Organisation erstellen",
        "selectCurrency": "Währung wählen"
      },
      "table": {
        "psp": "Zahlungsdienstleister",
        "edit": "Bearbeiten"
      }
    },
    "account": {
      "editTitle": "Konto bearbeiten",
      "passwords": {
        "password": "Passwort | Passwörter",
        "changePass": "Passwort ändern",
        "resetPass": "Passwort zurücksetzen",
        "forgotPass": "Passwort vergessen?",
        "currentPass": "Aktuelles Passwort",
        "newPass": "Neues Passwort",
        "confirmPass": "Passwort bestätigen",
        "apiErrors": {
          "weak_password": "Passwort kann nicht ähnlich wie Ihre E-Mail, Name oder ein schwaches Passwort wie \"admin1234\" sein"
        },
        "passwordInfo": {
          "title": "Wir erwarten ein starkes Passwort:",
          "minLength": "Minimale Länge von 9 Zeichen und nicht nur Zahlen",
          "similar": "Nicht ähnlich zu Ihrer E-Mail, oder schwache Passwörter wie \"admin123\"."
        }
      },
      "login": {
        "title": "Anmelden",
        "invalidCredentials": "Ungültige Anmeldedaten",
        "noAccount": "Noch kein Konto?",
        "username": "Benutzername"
      },
      "signup": {
        "title": "Registrieren",
        "hasAccount": "Hast du bereits ein Benutzerkonto?",
        "apiErrors": {
          "user_already_exists": "Es existiert bereits ein Konto mit dieser E-Mail-Adresse."
        }
      },
      "reset": {
        "description": "Gib Deine E-Mail-Adresse ein und wir senden Dir einen Link zum Zurücksetzen Deines Passworts.",
        "requestedDescription": "Wenn mit dieser E-Mail-Adresse ein Konto verknüpft ist, erhältst Du einen Link, um Dein Passwort zurückzusetzen.",
        "expiredAlert": "Dieser Link ist abgelaufen. Du kannst ein anderes Passwort anfordern.",
        "toLogin": "Zurück zur Anmeldung"
      }
    },
    "invite": {
      "title": "Einladung annehmen",
      "expired": {
        "alert": "Ihre Einladung ist nicht mehr gültig.",
        "info": "Für weitere Informationen oder Hilfe kontaktiere bitte {0}."
      },
      "noInvite": "Diese Einladung wurde bereits angenommen oder existiert nicht. Du kannst weiter zum Dashboard wechseln.",
      "accept": {
        "title": "Du wurdest eingeladen {org} beizutreten",
        "inviteAccepted": "Du hast nun Zugriff auf: {org}",
        "info": "Die Einladung wird unter Ihrem aktuellen Konto angenommen: {email}. Wenn Du die Einladung mit einem anderen Konto annehmen möchtest, melde dich zunächst bei diesem Konto an und klicke dann erneut auf den Einladungslink."
      }
    },
    "attendees": {
      "attendee": {
        "edit": {
          "changeNumber": {
            "title": "Nummer ändern",
            "description": "Du bist dabei, das Konto mit der Handynummer {number} zu ändern",
            "inputLabel": "Neue Handynummer"
          },
          "confirmNumber": {
            "attendees": "teilnehmer",
            "migrateConfirm": "Wenn du migrieren möchtest, bestätige bitte die neue Mobilfunknummer.",
            "existsDescription": "Es ist bereits ein Konto an diese neue Handynummer gebunden. Bist du sicher, dass dies korrekt ist?",
            "existsWithTickets": "Dieses Konto hat anstehende Event, daher kannst du nicht migrieren. Gehe zurück, wenn du eine andere Handynummer verwenden möchtest.",
            "inputLabel": "Bestätige die neue Handynummer"
          },
          "success": "Handynummer erfolgreich aktualisiert",
          "error": "Fehler: Handynummer konnte nicht aktualisiert werden"
        }
      }
    },
    "customers": {
      "title": "Your customers",
      "startSearch": "Search for customers across all your events by entering a (partial) name, email address or phone number. Easy peasy!",
      "loading": "Customer loading...",
      "groupsLoading": "Loading groups...",
      "tooltips": {
        "privilegesDescription": "The total number of entrance privileges allocated to a customer."
      },
      "tickets": {
        "ticketsTable": {
          "emptyText": "No tickets found, check orders for more information",
          "emptyTextShort": "No tickets found",
          "resoldBy": "by {customer}"
        }
      },
      "privileges": {
        "privilegesTable": {
          "emptyText": "No privileges found"
        }
      },
      "orders": {
        "ordersOverviewTitle": "Your orders"
      },
      "refunds": {
        "refundsTable": {
          "emptyText": "No refunds found"
        }
      },
      "customer": {
        "noName": "Unnamed customer",
        "noAddress": "No address",
        "edit": {
          "changeNumber": {
            "title": "Change number",
            "description": "You are about to change the account with mobile number {number}",
            "inputLabel": "New mobile number"
          },
          "confirmNumber": {
            "migrateInfo": "This mobile number can be changed. You can optionally check if this account already exists, by going to {customers}.",
            "customers": "customers",
            "migrateConfirm": "If you want to migrate, please confirm the new mobile number.",
            "existsDescription": "There is already an account tied to this new mobile number. Are you sure this is correct?",
            "existsWithTickets": "This account has upcoming events, therefore you cannot migrate. Go back if you want to use a different mobile number.",
            "inputLabel": "Confirm the new mobile number"
          },
          "success": "Successfully updated mobile number",
          "error": "Error: Could not update mobile number"
        }
      },
      "filters": {
        "pool": "Pool",
        "shop": "Shop",
        "checkin": {
          "name": "Checked-in status",
          "fully": "Fully",
          "partially": "Partially",
          "not": "Not"
        }
      }
    },
    "events": {
      "create": {
        "title": "Ereignis erstellen",
        "new": "Neu erstellen",
        "fromTemplate": "Aus Vorlage erstellen"
      },
      "title": "Deine Events",
      "titleWithOrganization": "Ihre Events für {org}",
      "reports": {
        "title": "Deine Verkäufe",
        "titleWithOrganization": "Ihre Verkäufe für {org}",
        "revenue": "Umsatz",
        "revenueBoxTitle": "Einnahmen in diesem Zeitraum",
        "ticketSoldBoxTitle": "In diesem Zeitraum verkaufte Tickets",
        "grossRevenue": "Brutto-Einnahmen",
        "invalidated": "Ungültig",
        "refunded": "Erstattet",
        "upsellSoldBoxTitle": "Up-Sells in diesem Zeitraum verkauft",
        "credits": {
          "title": "Credits",
          "circulating": "Im Umlauf",
          "info": "Du hast diesen Betrag an Guthaben zurückerstattet, aber er wurde noch nicht genutzt oder ist noch nicht verfallen"
        }
      },
      "eventCreate": {
        "title": "Neue {type} erstellen",
        "eventDescription": "Erstelle eine neue Veranstaltung von Grund auf! Diese Schritte helfen Dir dabei, eine Veranstaltung zu erstellen, die genau Deinen Bedürfnissen entspricht. Wähle zunächst die Art der Veranstaltung. Alle anderen Einstellungen kannst Du anschließend problemlos bearbeiten, mit Ausnahme der Veranstaltungsart. Natürlich kannst Du jederzeit wieder von vorne anfangen, denn die Veranstaltung wird als Entwurf gespeichert, bis Du sie für gut befindest. Kein Druck, Du schaffst das schon.",
        "templateDescription": "Erstelle eine neue Veranstaltungsvorlage von Grund auf! Diese Schritte führen Dich dazu, die Grundeinstellungen für eine Veranstaltung festzulegen, die Du als eigene Vorlage verwenden kannst. Du kannst alle Einstellungen nachträglich bearbeiten, mit Ausnahme der in diesem ersten Schritt gewählten Veranstaltungsart.",
        "templateInfo": "Sobald Du eine Veranstaltungsvorlage erstellt hast, kannst Du automatisch mehrere Veranstaltungen mit demselben Aufbau erstellen. Alle Einstellungen in der Vorlage werden kopiert und für alle Veranstaltungen übernommen. ",
        "setEventType": "Art der Veranstaltung",
        "selectType": {
          "label": "Wähle die Art der Veranstaltung, die Du erstellen möchtest"
        },
        "selectVenue": "Ort auswählen",
        "eventDetails": "Ereignisdetails",
        "ticketManagement": "Ticket-Verwaltung",
        "modal": {
          "title": "Vorschau des erstellten Events",
          "content": "Dein neues Ereignis wurde erfolgreich erstellt und unter Deine Entwürfen gespeichert.<br><br>Du kannst dein Event sofort veröffentlichen oder zurück zum Dashboard gehen, um vorher weitere Änderungen vorzunehmen.",
          "dashboardButton": "Zurück zum Dashboard",
          "publishButton": "Ereignis veröffentlichen"
        }
      },
      "eventCopy": {
        "action": "Ereignis kopieren",
        "description": "Alle Konfigurationen der Event werden zusammen mit den Haltern kopiert.",
        "startDate": "Startdatum"
      },
      "templateCopy": {
        "action": "Vorlage kopieren",
        "description": "Alle Konfigurationen der Vorlage werden zusammen mit den Warteschlangen kopiert."
      },
      "event": {
        "savedAsDraft": {
          "title": "Event-Entwurf wurde gespeichert",
          "draftInfo": "Dein Event wurde erfolgreich erstellt und als Entwurf gespeichert. Du kannst dein Ereignis im Dashboard unter Deine Entwürfe einsehen und bearbeiten.",
          "previewInfo": "Du kannst deine Event jetzt als Vorschau betrachten und später veröffentlichen oder direkt veröffentlichen. Drücke OK, um zum Dashboard zurückzukehren."
        },
        "demo": {
          "description": "Demo event: This is event has been created under a demo organisation. Real payments are disabled. Only use this event for demo or testing purposes."
        },
        "unlink": {
          "title": "Dieser Termin wird von einer Vorlage verwaltet.",
          "description": "Änderungen an Shops, Tickets, Pools und anderen Eventeinstellungen können nur in {0} des Events gleichzeitig vorgenommen und synchronisiert werden.",
          "eventTemplate": "Vorlage",
          "shop": {
            "title": "Dieser Shop wird von einer Vorlage verwaltet.",
            "description": "Änderungen an diesem Shop, seinen Tickets und Gruppen können nur in {0} vorgenommen und mit allen Veranstaltungen auf einmal synchronisiert werden.",
            "link": "der Vorlagen-Shop für diese Veranstaltung"
          },
          "action": {
            "description": "Wenn du nur Änderungen an diesem Ereignis vornehmen möchtest {0}",
            "unlink": "von Vorlage entkoppeln."
          },
          "modal": {
            "title": "Bist du sicher, dass du die Verknüpfung zu diesem Event aufheben möchtest?",
            "description": "Wenn du dieses Ereignis von seiner Vorlage entfernst, wird es nicht mehr aktualisiert, wenn Änderungen in dieser Vorlage vorgenommen und synchronisiert werden. Du kannst es komplett als ein einziges Ereignis verwalten. Diese Aktion kann nicht rückgängig gemacht werden!",
            "action": {
              "unlink": "entfernen",
              "description": "Gib {0} in das Feld unten ein, um es zu bestätigen"
            },
            "unlinkEvent": "Ereignis aufheben"
          }
        },
        "manage": {
          "placeholders": {
            "title": "e.g. Maximum Music Festival",
            "subtitle": "e.g. 20th Aniversary",
            "shortName": "e.g. MaxMusic"
          },
          "shortNameExplanation": "Will be used for SMS notifications when issuing tickets through the dashboard",
          "generalTitle": "Allgemeine Ereignisdetails verwalten",
          "additionalTitle": "Weitere Ereignisdetails verwalten",
          "advancedTitle": "Erweiterte Ereignisdetails verwalten",
          "saveValidateAlert": "Einige Pflichtfelder fehlen oder haben ungültige Daten.<br>Bitte überprüfe die obige Fehler.",
          "marketing": "Marketing",
          "scannerPassword": "Scanner-Passwort",
          "scannerQr": "QR code",
          "about": {
            "label": "Über dein Event",
            "description": "Beschreibe, worum es bei deinem Event geht, teile deinen Besuchern zusätzliche Richtlinien mit, gib allgemeine Informationen und externe Links an.",
            "help": "Grundlegendes Styling kann durch Auswahl des gewünschten Textes hinzugefügt werden."
          },
          "endDateSwitch": "Dieses Event endet nach Mitternacht am nächsten Tag",
          "endDateDescription": "Wenn ein Event nach Mitternacht endet (z.B. 01:00) endet es technisch einen Tag später - früh morgens. Schalte dies ein, um zu kommunizieren, dass das Ereignis nicht am nächsten Tag endet.",
          "templateStartDateInfo": "Verwendet das angegebene Datum, wenn ein Ereignis aus dieser Vorlage erstellt wird.",
          "dateErrors": {
            "required": "Alle Datums- und Zeitfelder sind Pflichtfelder. Bitte fülle unbedingt alle Informationen aus.",
            "minOpen": "Die Einlasszeit darf nicht nach der Startzeit der Veranstaltung liegen.",
            "minOpenLivestream": "The opening time for livestream should be at least 15 min before the event start."
          },
          "coverImage": {
            "label": "Cover-Foto",
            "description": "Fügen ein Foto von hoher Qualität hinzu, das in verschiedenen Größen angezeigt wird, je nachdem, wo es in der App verwendet wird. Versuche, Text oder Logos im Bild zu vermeiden.",
            "help": "PNG- oder JPG-Dateityp. Mindestens 640x480 Auflösung. Maximal 10MB Dateigröße."
          },
          "floorplanImage": {
            "label": "Karte oder Grundriss",
            "description": "Wird von GUTS Tickets im eps Format zur Verfügung gestellt.",
            "help": "SVG oder PNG Dateityp. Beste Auflösung ist 375 Pixel breit."
          },
          "orderSuccessLink": {
            "label": "Call-to-Action, wenn Tickets gekauft werden",
            "description": "Zeige dem Käufer eine kurze Nachricht und rufe ihn zum Handeln auf."
          },
          "orderSuccessEmail": {
            "label": "E-Mail-Marketing",
            "description": "Benutzerdefinierter Inhalt für Ticket-Bestätigungs-E-Mails."
          },
          "saveAlert": {
            "title": "Änderungen speichern",
            "description": "Bist du sicher, dass du die Änderungen, die du für dieses veröffentlichte Ereignis vorgenommen hast, speichern möchtest? Besucher werden die Änderungen direkt sehen können."
          },
          "publishAlert": {
            "title": "Event öffentlich machen",
            "description": "Bist du sicher, dass du dieses Event veröffentlichen und verfügbar machen möchtest? <br> Sobald ein Event veröffentlicht wurde kannst du es <b>nicht</b> mehr auf Entwurf zurücksetzen. Tickets werden jedoch erst zum eingegebenen Verkaufsstart verfügbar sein.",
            "shopsTitle": "Your event and the following shops will become public",
            "cancel": "Not now",
            "confirm": "Publish now",
            "warning": "Publishing cannot be undone",
            "shop": "Shop",
            "defaultGate": "Default Gate",
            "opens": "Opens on",
            "immediately": "Immediately",
            "shopsFailed": "Failed to load shops, please try again",
            "tryAgain": "Try again",
            "publishError": "The event could not be published. Please make sure that the payment service provider configuration of your organisation is fully setup.",
            "orgSettings": "Go to settings for: {name}"
          },
          "livestream": {
            "typeSelect": {
              "label": "Wähle aus, wie Du Deinen Livestream konfigurieren möchtest (optional)",
              "internal": "Intern: Verwende deine eigenen Einbettungscodes",
              "external": "Extern: Verwende einen externen Link zur Weiterleitung zu"
            },
            "videoEmbed": {
              "label": "Livestream Video-Einbettungscode (optional)",
              "placeholder": "<iframe src='https://player.vimeo.com/video/123456789/' frameborder=0 allow=autoplay; fullscreen allowfullscreen></iframe>"
            },
            "chatEmbed": {
              "label": "Livestream Chat-Einbettungscode (optional)",
              "placeholder": "<iframe src='https://vimeo.com/live-chat/123456789/' frameborder='0'></iframe>"
            },
            "externalUrl": {
              "label": "Livestream externe URL (optional)",
              "placeholder": "https://livestream.com"
            }
          },
          "advanced": {
            "sharing": {
              "title": "Teilen",
              "label": "Ticket-Sharing zwischen Teilnehmern (optional)",
              "strict": "Über QR-Code teilen",
              "strictInfo": "Teilnehmer müssen sich persönlich treffen und einen QR-Code scannen um ihre Tickets miteinander zu teilen",
              "easy": "Über Link teilen",
              "easyInfo": "Teilnehmer können einen Link senden, um andere zur Teilnahme einzuladen",
              "disabled": "Nicht erlaubt",
              "disabledInfo": "Teilnehmer können ihre Tickets für diese Veranstaltung nicht teilen"
            },
            "private": {
              "title": "Blockchain Visibility",
              "label": "It's possible to hide your event details on the blockchain. This includes event name, location, image and the shop URL",
              "checkbox": "Do not show event details on the blockchain"
            },
            "orderConfirmEmail": {
              "title": "E-Mail zur Bestellbestätigung",
              "label": "E‐Mail‐Adresse (optional)",
              "placeholder": "beispiel{'@'}organisator.com",
              "info": "Wir senden Ihnen eine E-Mail an diese Adresse mit Details zu jeder Bestellung."
            },
            "scanLeeway": {
              "title": "Spielraum für Offline-Scanning",
              "label": "Eingestellte Zeit in Minuten",
              "info": "Die Teilnehmer können sich mit einem QR-Code anmelden, für den keine Internetverbindung erforderlich ist. Diese Einstellung legt fest, wie lange diese Offline-QR-Codes gültig sind."
            }
          },
          "blockchain": {
            "publish": {
              "title": "Visibility",
              "label": "When you publish, your event & shop information will also be visible on the GET Protocol blockchain. You can (optionally) specify when that should happen:",
              "never": "Do not show event details on the blockchain",
              "public": "This event's information has already been made public on the blockchain. It is not reversible.",
              "defaultInfo": "By default this is set to the opening time of your default shop."
            },
            "contractAddress": {
              "title": "Event contract address",
              "placeholderDraft": "Your event contract address will be generated once the event is published.",
              "label": "Below is the event contract address for you to view and verify all transactions.",
              "placeholder": "Generating (refresh the page to update)"
            },
            "nft": {
              "title": "Default Collectible Artwork",
              "label": "Upload your default Collectible artwork here. This design will be used for all ticket's collectibles (unless specifically overwritten within each ticket's settings).",
              "guidelines_title": "General guide lines:",
              "line_1": "PNG, GIF or JPG file type",
              "line_2": "Portrait mode with aspect ratio of 3:4",
              "line_3": "10 MB max file size",
              "line_4": "You can <a href='{href}' download>our template</a> as a base",
              "note": "Note: If no artwork is provided, a default artwork is used by GET Protocol."
            }
          },
          "resale": {
            "title": "Resale Fees"
          },
          "previewModal": {
            "title": "Send preview email",
            "success": "Email has been sent successfully",
            "error": "An error occured sending the email"
          }
        },
        "reports": {
          "sales": {
            "turnover": "Umsatz",
            "capacity": "Kapazität gefüllt",
            "ticketsSold": "Verkaufte Tickets",
            "capacityFilled": "Kapazität gefüllt",
            "capacitySold": "Kapazität verkauft",
            "tooltipTicketsSold": "Verkauft",
            "availabilityTooltip": "Tickets können Pools teilen",
            "percentageSold": "Prozentsatz verkauft",
            "revenue": "Umsatz",
            "grossRevenue": "Brutto-Einnahmen",
            "graph": {
              "range": {
                "placeholder": "Zeitraum auswählen",
                "last_day": "Letzte 24 Stunden",
                "last_month": "Letzten 31 Tage",
                "all_time": "Gesamt"
              },
              "title": {
                "last_day": "Tickets verkauft letzte 24 Stunden",
                "last_month": "Letzten 31 Tage verkaufte Tickets",
                "all_time": "Alle Tickets pro Woche verkauft"
              }
            }
          },
          "demographics": {
            "avgOrderSize": "Durchschnittliche Anzahl von Tickets pro Bestellung",
            "totalBuyers": "Gesamtzahl der Käufer",
            "locations": "Standorte"
          },
          "execution": {
            "checkedInPercentage": "Prozentsatz eingecheckt"
          }
        },
        "attendees": {
          "filters": {
            "checkin": {
              "label": "Nach Check-in filtern",
              "partialExecuted": "Teilweise Check-in",
              "allExecuted": "Check-in abschließen",
              "notExecuted": "Nicht eingecheckt",
              "all": "Alle anzeigen"
            },
            "pools": "Nach Pool filtern"
          },
          "guestlist": {
            "action": "Gäste hinzufügen",
            "addRow": "Zeile hinzufügen",
            "guestListName": "Name der Gastliste",
            "createVariant": "Neu erstellen...",
            "selectTicket": "Ticket auswählen",
            "selectVariant": "Gästeliste auswählen",
            "optionsTitle": "Zur kostenlosen Gästeliste hinzufügen",
            "seating": "Blockierte Sitze auswählen",
            "selectPool": "Ticket-Pool auswählen",
            "confirmTitle": "Gästeliste bestätigen"
          },
          "cashier": {
            "selectTickets": "Select tickets",
            "blockCategory": "Block category",
            "ticketsLeft": "Tickets left",
            "specifyAmount": "Please specify how many {ticketName} tickets you would like to add",
            "cart": "Cart ({count})",
            "emailField": "Send confirmation to (optional)",
            "notifyBySms": "Notify via SMS",
            "notifyByEmail": "Notify via email",
            "addTicket": "Add {ticketName} Ticket",
            "addTicketDescription": "From which <strong>availability</strong> do you want to select your tickets from?",
            "addTicketAssignment": "How would you like to assign the seats?",
            "addTicketHowMany": "How many <strong>{ticketName}</strong> tickets from <strong>{shopName}</strong> would you like to add?",
            "addToCart": "Add to cart",
            "emailTooltip": "If no email address is specified, no confirmation email will be send.",
            "ticketsLoading": "Retrieving available tickets, please wait...",
            "tickets": "Tickets",
            "issueDigitallyCheck": "Send tickets to phone?",
            "yourOrder": "Your order {orderId} for {customerName}",
            "orderCreated": "Order {orderId} created",
            "issueMore": "Issue more tickets",
            "reserveMore": "Reserve more tickets",
            "bookPayment": "Book payment",
            "distributeTickets": "Distribute tickets",
            "checkinAll": "Check all tickets in now",
            "noTicketsSelected": "No tickets selected",
            "confirmGuestInfo": {
              "confirmTitle": "Confirm Tickets",
              "confirmMainMessage": "Tickets will be linked to the provided phone number(s) and can be accessed by the attendee via the ticket wallet.<br/>Attendees will receive an e-mail confirmation with the event & ticket info.",
              "total": "Total",
              "hasEmail": "Confirmation sent to: {email}",
              "noEmail": "No confirmation sent (no email address provided)"
            },
            "actionTooltip": "Tickets can only be issued when the event has been published",
            "action": "Tickets verkaufen",
            "optionsTitle": "Tickets verkaufen",
            "categories": {
              "title": "Blockkategorie auswählen",
              "description": "Im Folgenden findest du alle nicht besetzten Tickets. Bitte wähle die Kategorie aus, aus der dein Ticket verkauft werden soll. Wenn in einer Kategorie nicht genügend Blöcke verfügbar sind, kannst du sie bearbeiten {0}",
              "categoryLink": "hier."
            },
            "payment": {
              "label": "Zahlung erhalten von",
              "placeholder": "Zahlungsart"
            },
            "seatingMode": {
              "auto": "Assign seats automatically",
              "manual": "Pick seat(s) now",
              "autoseatInfo": "After event organiser finalises the seating"
            },
            "issue_now_modal": {
              "title": "What do you want to do next?",
              "choices": {
                "issue": "Issue tickets",
                "reservation": "Create reservation"
              }
            },
            "add_to_reservation_modal": {
              "title": "Add selection to reservation",
              "description": "You are about to add your selection to the reservation. Are you sure?"
            },
            "reservationForm": {
              "expiryDate": "Expiry Date",
              "details": "Details",
              "reservationName": "Reservation Name",
              "note": "Note",
              "contactDetails": "Contact information",
              "firstName": "First Name",
              "lastName": "Last Name",
              "mobileNumber": "Mobile Number",
              "email": "Email",
              "notePlaceholder": "E.g. Team Building Event"
            }
          },
          "blockCategories": {
            "notfound": "Kategorie nicht gefunden...",
            "createCategory": "Kategorie erstellen",
            "createCategoryTitle": "Kategorie erstellen",
            "deleteTooltip": "Nur leere Kategorien können entfernt werden",
            "editCategory": "Kategorie bearbeiten",
            "categoryName": "Kategoriename",
            "categoryNamePlaceholder": "z.B. Techniker",
            "categoryShortNamePlaceholder": "ABC (max. 3)",
            "categoryShortName": "Shortcode",
            "uniqueShortNameError": "Shortcodes müssen einzigartig sein",
            "uniqueNameError": "Eine Kategorie mit diesem Namen existiert bereits",
            "ungrouped": "Nicht kategorisiert",
            "ungroupedShort": "UNC",
            "selected": "{selected} von {total} ausgewählt",
            "unblocked": "Blöcke erfolgreich entfernt",
            "moved": "Blöcke erfolgreich in Kategorie verschoben",
            "printTickets": "Print / PDF",
            "movePrivs": "In Kategorie verschieben",
            "noCategory": "Keine Kategorie",
            "editNote": "Notiz bearbeiten",
            "addNote": "Notiz hinzufügen",
            "floorplanLabel": "Kategorien blockieren",
            "addPrivs": "Blöcke hinzufügen",
            "addPrivsModal": {
              "title": "Füge neue Blöcke zur Kategorie \"{category} \" hinzu",
              "selectPool": "Pool auswählen",
              "addSeated": "Sollen bestimmte Sitze blockiert werden? {0}",
              "addSeatedLink": "Gehe zum Grundriss"
            },
            "categorySearch": "Wähle eine Kategorie...",
            "details": {
              "ticket": "Stufe / Ticket",
              "filterClear": "Filter löschen",
              "filterTicket": {
                "empty": "Nach Ebene / Ticket filtern",
                "selected": "Gefiltert nach {name}"
              },
              "filterSection": {
                "empty": "Nach Abschnitt filtern",
                "selected": "Gefiltert nach {name}"
              },
              "filterClaimed": {
                "empty": "Nach Status filtern",
                "selected": "Gefiltert nach eingecheckt"
              }
            },
            "list": {
              "fields": {
                "name": "Kategorie"
              }
            },
            "editTicketNote": {
              "titleEdit": "Notizen bearbeiten)",
              "titleAdd": "Notiz (n) hinzufügen",
              "multipleNotes": "Du bearbeitest mehrere Notizen. Diese Änderung betrifft alle Blöcke, die Du ausgewählt hast.",
              "placeholder": "Notiz"
            },
            "unblockModal": {
              "title": "Sperre(n) freigeben",
              "description": "Die ausgewählten Blöcke werden freigegeben und zum Verkauf freigegeben.",
              "descriptionCheckedIn": "Bitte beachten: Eingecheckte Blöcke können nicht freigegeben werden, sie werden einfach nicht kategorisiert."
            }
          },
          "externalCodes": {
            "title": "Externe Tickets",
            "ticketInfo": {
              "ticketsImported": "Gesamte importierte Tickets",
              "lastImport": "Letzter Import",
              "ticketsCheckedIn": "Gesamte eingecheckte Tickets"
            },
            "importCSV": {
              "title": "Externe Tickets mit csv importieren",
              "description": "Akzeptieren und Einchecken von externen Tickets im Scanner, indem du deine Barcodes über eine csv-Datei importierst. Die Struktur der <strong>csv</strong> Datei sollte entweder eine einzelne Spalte sein, die den Barcode enthält, oder die Spalte mit dem Namen 'Barcode' (ohne Anführungszeichen) enthalten.",
              "placeholder": "Wähle csv Datei oder lege sie hier...",
              "deleteAndReplace": "Bestehende Tickets ersetzen. Nicht eingecheckte Tickets werden gelöscht",
              "parsingError": "Keine Barcodes gefunden. Verwende eine einzelne Spalte oder den Namen der Spalte 'Barcode' (ohne Anführungszeichen)",
              "uploadFailInvalidCSV": "Die angegebene Datei ist keine gültige csv",
              "uploadFailNoCodes": "Die angegebene Datei hat keine Codes innerhalb",
              "uploadFail": "Die csv-Datei konnte nicht importiert werden",
              "uploadSuccess": "{nbTickets} Tickets importiert. Beispiel für einen importierten Barcode: {barcodeExample}",
              "fileTooBig": "Die csv-Datei, die du importieren möchtest, ist zu groß. Die maximale Größe beträgt 6 MB.",
              "emptyFile": "Die csv-Datei, die du importieren möchtest, ist leer"
            }
          },
          "attendee": {
            "invalidateSuccess": "Die Tickets wurden erfolgreich ungültig gemacht",
            "invalidationFail": "Die Ungültigkeit ist fehlgeschlagen, bitte versuche es erneut",
            "invalidateTicketConfirm": {
              "manual": "Manual refund",
              "auto": "Automatic refund",
              "credits": "Refund as credits",
              "noRefund": "Invalidate without refund",
              "creditsExpiration": "Expiration date",
              "creditsConfirm": "These credits will expire on {0} after which they can not be used anymore",
              "titleWithoutRefund": "Bestätige ungültige",
              "refundType": "Wie möchtest du die folgenden Tickets zurückerstatten?",
              "partialRefundInfos": "Füllen den Betrag aus, den du pro Ticket abziehen möchtest",
              "deduct": "To deduct",
              "fullRefund": "Vollständige Rückerstattung",
              "partialRefund": "Teilerstattung",
              "deducted": "Abgeführt",
              "subtitle": "Du bist dabei das folgende Ticket ungültig zu machen es zurückzuerstatten | Du bist dabei, die folgenden Tickets ungültig zu machen und zurückzuerstatten:",
              "subtitleWithoutRefund": "Du bist dabei, das folgende Ticket ungültig zu machen | Du bist dabei, die folgenden Tickets ungültig zu machen:",
              "totalAmount": "Gesamtbetrag",
              "refundAmount": "Rückerstattung",
              "partialRefundErrorMessage": "Der Betrag ist zu hoch",
              "toolTipText": "Wenn du diese Option nicht auswählst, werden Tickets erneut verkauft",
              "selectBlock": "Blockkategorie auswählen",
              "blockCheckbox": "Die wieder verfügbaren Tickets sperren",
              "feeMessage": "Die Servicegebühr wird berechnet:",
              "summaryMessage": "Folgende Ticket(s) werden ungültig.",
              "category": "The tickets are blocked on category {0}",
              "actionUndoneInfo": "Diese Aktion kann nicht rückgängig gemacht werden.",
              "extraConfirmModal": {
                "extraConfirmMessage": "Der Erstattungsbetrag ist {0}. Diese Aktion kann nicht rückgängig gemacht werden. Bist du sicher, dass du die ausgewählten Tickets ungültig machen und erstatten möchtest?",
                "extraConfirmTitle": "Invalidierung und Rückerstattung abschließen",
                "extraFinalizeMessage": {
                  "text": "Gib {0} in das Feld unten ein, um es zu bestätigen",
                  "finalize": "FINALISIEREN"
                }
              }
            },
            "invalidateTicketOptions": {
              "title": "Tickets entwerten und erstatten",
              "missingPaymentMethod": "Bitte wähle eine Zahlungsart",
              "subtitle": "Wähle aus, ob und wie du die Rückerstattungen der Tickets, die du ungültig machen willst, verarbeiten möchtest:",
              "tooltip": "Der rückerstattbare Betrag ist kleiner als der gekaufte Betrag. Dies kann durch teilweise Rückerstattungen in der Vergangenheit oder mehrere genutzte Zahlungsmethoden bedingt sein.",
              "automatic": {
                "title": "Mit automatischer Rückerstattung ungültig",
                "description": "Erstattung des Betrags für die Tickets automatisch an den Teilnehmer. Wähle im nächsten Schritt aus, ob dem Teilnehmer oder dem Veranstalter die Servicegebühr berechnet wird."
              },
              "credits": {
                "title": "Refund as credits",
                "description": "Erstatte den Betrag zurück und füge das Guthaben dem Konto des Benutzers hinzu, der später eingelöst werden soll."
              },
              "manual": {
                "title": "Mit manueller Rückerstattung ungültig",
                "description": "Erstatte manuell mit einer externen Zahlungsmethode und passe die Berichte entsprechend an. Wähle im nächsten Schritt aus, ob dem Teilnehmer oder dem Veranstalter die Servicegebühr berechnet wird."
              },
              "noRefund": {
                "title": "Ungültig ohne Rückerstattung",
                "description": "Die Tickets werden nur ungültig und behalten den ursprünglich bezahlten Betrag in den Berichten."
              }
            },
            "ticketBuyer": "Käufer",
            "noGender": "Kein Geschlecht",
            "noAge": "Kein Alter",
            "noZipcode": "Keine Postleitzahl",
            "noCountry": "Kein Land",
            "noPhoneNumber": "No phone number",
            "noEmail": "Keine E-Mail",
            "noWalletLink": "No wallet link",
            "ticketOwner": "Owns tickets",
            "activeGroupMember": "Group member",
            "inactiveGroupMember": "Has left group",
            "customerTickets": "Tickets",
            "customerOrders": "Orders",
            "customerPayments": "Payments",
            "customerRefunds": "Refunds",
            "groupTickets": "Gruppentickets",
            "share": {
              "action": "Tickets teilen",
              "label": "Alle Tickets teilen mit:",
              "description": "Bestehendes oder neues Konto",
              "newUser": "Neues Konto",
              "anonymousUser": "Anonymes Konto",
              "alert": {
                "title": "Tickets teilen",
                "description": "Bist du sicher, dass du <strong>{count} Tickets</strong> mit <strong>{from} und <strong>{to}</strong> teilen möchtest?",
                "newUserNotice": "Für das neue Mitglied wird ein neues Konto erstellt.",
                "userNotice": "Tickets werden mit dem bestehenden Konto von <strong>{name}</strong> freigegeben."
              }
            },
            "remove": {
              "title": "Ticket löschen | Tickets löschen",
              "description": "Bist du sicher, dass du dieses Ticket löschen möchtest? | Bist du sicher, dass du diese Tickets löschen möchtest?"
            },
            "checkin": {
              "title": "Check-in",
              "description": "Bist du sicher, dass du diese Tickets einchecken möchtest?",
              "combiTickets": "Wähle, was du für die Kombi-Tickets einchecken möchtest:"
            },
            "undoCheckin": {
              "title": "Check-in rückgängig",
              "description": "Bist du sicher, dass du den Check-in rückgängig machen möchtest?",
              "combiTickets": "Wähle, was du für den Check-in für die Combi Tickets rückgängig machen möchtest:"
            },
            "email": {
              "title": "E-Mail-Teilnehmer",
              "error": "Diese E-Mail ist ungültig",
              "sendEmails": "E-Mails senden",
              "orderEmail": "Zahlungsbestätigung",
              "seatingEmail": "Sitzdetails"
            },
            "convert": {
              "selectOption": "Konvertierungsoptionen auswählen",
              "convertTo": "Konvertiere in",
              "selectPool": "Ticket-Pool auswählen",
              "selectKind": "Ticket auswählen",
              "selectShop": "Shop auswählen",
              "left": "übrig: {amount}",
              "paymentMethod": "Geld erhalten von",
              "paymentOptions": "{method} oder {free}",
              "sendEmail": "Bestätigungs-E-Mail an Kunden senden",
              "selectPayment": "Zahlungsart",
              "total": "Preisdifferenz:",
              "new": "Neu erstellen",
              "confirmConversion": "Konvertierung bestätigen"
            },
            "ticketDetails": {
              "id": "ID",
              "price": "Price",
              "order": "Order",
              "orderDate": "Order date",
              "shop": "Shop",
              "ownedBy": "Owned by",
              "status": "Status",
              "resoldBy": "Resold by",
              "on": "On",
              "onDate": "on {date}",
              "sellerPrice": "Seller Price",
              "buyerPrice": "Buyer Price",
              "buyerFees": "Buyer Fees",
              "sellerFee": "Seller Fees",
              "invalidatedBy": "Invalidated by",
              "refundMethod": {
                "cash": "Cash",
                "pin": "Pin",
                "digital": "Digital",
                "other": "Other"
              },
              "fees": "Fees",
              "payOut": "Payout",
              "resoldTo": "Resold to",
              "ticketId": "Ticket ID",
              "putForSaleBy": "Put for sale by",
              "privileges": "Privileges",
              "boughtBy": "Bought by",
              "boughtFrom": "Bought from"
            }
          }
        },
        "seating": {
          "legend": "Legende",
          "sectionList": "Abschnitte",
          "editNote": "Notizen bearbeiten",
          "standingSection": "Stehendes Kapitel",
          "capacity": "Kapazität",
          "overview": "Übersicht",
          "notePlaceholder": "Notiz hinzufügen...",
          "notEnoughFree": "Nicht genug kostenlos",
          "changes": "Änderungen",
          "seatClaimed": "Eingecheckte Tickets können nicht bearbeitet werden",
          "categoryInfo": "Wähle zuerst eine Kategorie aus der Liste unten aus, wenn du Sitze einer bestimmten Blockkategorie zuweisen möchtest. Wenn du dies nicht tust, werden deine ausgewählten Plätze nicht kategorisiert.",
          "assign": {
            "title": "Teilnehmer Sitze zuweisen",
            "showAssigned": "Zugewiesene anzeigen",
            "allTicketTypes": "Alle Pools",
            "onlyMultipleTicketTypes": "Kombination mehrerer Pools"
          },
          "solve": {
            "title": "Autositz für alle Teilnehmer",
            "description": "Mit Autoseat werden alle Teilnehmer automatisch auf die besten verfügbaren Plätze verteilt, die für diese Gruppengröße in jedem Rang zur Verfügung stehen. Möchtest du bestimmte Ticket-Inhaber zusammenfassen? Du kannst optional eine Gruppe von bis zu 3 Account-Telefonnummern eingeben.",
            "groupPlaceholder": "Telefonnummer",
            "addGroup": "Gruppe hinzufügen",
            "success": "Erfolgreich gelöst!"
          },
          "finalize": {
            "title": "Aktuelle Sitzplätze abschließen",
            "description": "Bist du sicher? Das Finalisieren kann nicht rückgängig gemacht werden, alle Teilnehmer erhalten ihre Sitzinformationen per E-Mail und SMS.",
            "instructions": "Gib {0} in das Feld unten ein, um zu bestätigen:",
            "success": "Erfolgreich fertiggestellt!"
          },
          "clearSuccess": "Erfolgreich gelöscht!"
        },
        "scanning": {
          "actions": {
            "create": "Profil erstellen",
            "edit": "Profil bearbeiten",
            "cannotEdit": "Dieses Profil kann nicht bearbeitet werden"
          },
          "creation": {
            "description": "Erstelle ein Profil, das, wenn es in der Scanner-App geladen wird, Tickets, die die angegebenen Pools verwenden, filtert und nur anzeigt."
          },
          "description": "{poolAmount} -Pool kann mit diesem Scan-Profil gescannt werden. Folgenden QR-Code scannen um sich anzumelden. | {poolAmount} Pools können mit diesem Scan-Profil gescannt werden. Folgenden QR-Code scannen, um sich anzumelden.",
          "mainProfile": {
            "title": "Haupt",
            "description": "Dies ist das Hauptprofil, das das Scannen aller Pools gestattet. Scanne den folgenden QR-Code zum Anmelden.",
            "warning": "Achtung! Dieser QR-Code erlaubt nur das Scannen dieses bestimmten Events. Wenn du alle deine (zukünftigen) Event {0} scannen möchtest, kannst du diese Funktion nutzen."
          }
        },
        "shop": {
          "deleteShopModalTitle": "Shop löschen",
          "deleteShopMessage": "Bist du sicher, dass du diesen Shop löschen möchtest",
          "deleteShopTooltip": "Wenn di den Shop löschen möchtest, entferne zuerst alle Tickets",
          "copyInfo": "Alle Tickets und das Shop-Layout werden in einen neuen Shop kopiert. Klicke auf Bestätigen, wenn du fortfahren möchtest.",
          "notfound": "Shop nicht gefunden...",
          "title": "Tickets verwalten",
          "description": "Füge Tickets oder Kombi-Tickets zu deinem Event hinzu",
          "ticketModalTitle": "Ticket erstellen",
          "vat": "VAT",
          "baseTicket": "Basis-Ticket",
          "deleteHelper": "Tickets können nur entfernt werden, wenn noch keine verkauft wurde.<br>du kannst jederzeit ein Ticket ausblenden.",
          "newTicket": "Neues Ticket",
          "price": "Gesamtpreis",
          "tax": "VAT (incl.)",
          "breakdown": "Preisaufteilung",
          "artwork": {
            "toggle": "Collectible Artwork",
            "error": "Your image could not be uploaded. Please try again later.",
            "title": "Upload your Collectible artwork for this ticket which will override any default Collectible artwork set under the event's Blockchain tab.<br><br>You can update this artwork at any point in the event lifecycle.",
            "template": "download template",
            "update": "Update art for NFT's already minted",
            "guidelines": {
              "title": "General guide lines",
              "line_1": "PNG, GIF or JPG filetype",
              "line_2": "Portrait mode with aspect ratio of 3:4",
              "line_3": "10 MB max file size",
              "line_4": "You can use <a href='{href}' download>our template</a> as a base"
            },
            "note": "Note: Unless you upload an image, your event NFT artwork will be used by default."
          },
          "availabilitySettings": "Availability settings",
          "advanced": "Erweiterte Einstellungen",
          "maxTicketsLabel": "Ticket-Limit",
          "putForSale": "Ticket zum Verkauf stellen",
          "from": "Verfügbar ab",
          "till": "Verfügbar bis",
          "defaultShopTooltip": "Diesen Shop standardmäßig verwenden, wenn ein Benutzer einen Link zu dieser Veranstaltung teilt.",
          "nameInfo": "Nur in Dashboard verwendet",
          "ticketLimitInfo": "Maximale Anzahl der Tickets, die ein Benutzer in diesem Shop kaufen kann",
          "advancedEditPoolsLabel": "Lege die Anzahl der Berechtigungen aus Pool fest, die der Benutzer beim Kauf dieses Tickets erhält (max. 20 pro Pool)",
          "maxAmount": "Maximaler Betrag",
          "pages": {
            "deletePageModalTitle": "Seite löschen",
            "deletePageMessage": "Alle Tickets auf dieser Seite werden zur ersten Seite verschoben. Bist du sicher, dass du diese Seite löschen möchtest?",
            "actions": {
              "moveToPage": "Zur Seite verschieben",
              "create": "Seite erstellen",
              "edit": "Seite bearbeiten"
            }
          },
          "access": {
            "title": "Links & Einbettungscodes für Shop - {name}",
            "linksTitle": "Dieser Shop ist über die folgenden Gates & Links erreichbar:",
            "description": "Gates können mehrere Shops über verschiedene Veranstaltungen hinweg halten, von denen der Benutzer wählen kann. Standardmäßig hat jeder Shop sein eigenes einzelnes Gate (Link).",
            "draftAlert": "Dieses Event ist noch nicht veröffentlicht. Das/die Gate(s) zeigen dieses Ereignis erst an, wenn es veröffentlicht ist.",
            "singleGate": "Einzel Shop Gate"
          },
          "availability": {
            "title": "Verfügbarkeit",
            "description": "Standardmäßig stehen Tickets <strong>{from}</strong> bis <strong>{ends}</strong>zur Verfügung. Die maximale Anzahl der bestellbaren Tickets beträgt <strong>{amount}</strong> Tickets pro Konto.",
            "immediately": "sofort",
            "eventEnds": "die Veranstaltung endet",
            "relative": "{days} Tage vor dem Ende der Veranstaltung, um {time}",
            "absoluteStart": "von {date}",
            "startLabel": "Shop öffnet",
            "endLabel": "Shop schließt"
          },
          "accessControl": {
            "title": "Access to Shop",
            "description": "Access to the store will be provided once a Collection is found in the Customer's Wallet. Select Collection(s) that will be used to grant access to the Shop.",
            "linkLabel": "Link to OpenSea",
            "urlPlaceholder": "opensea.io/...",
            "collectionsListLabel": "Collections selected",
            "errors": {
              "collectionNotFound": "The collection was not found.",
              "invalidUrl": "This is not a valid OpenSea collection URL.",
              "duplicateUrl": "This collection is already added.",
              "wrongChain": "The chain where this collection is deployed is not supported. Currently, we only support Ethereum Mainnet, Polygon Mainnet and Polygon Mumbai Testnet.",
              "openseaError": "An error occurred while fetching the collection. Please try again later.",
              "invalidCollection": "This collection doesn't have an NFT contract."
            }
          },
          "empty": {
            "action": "Erstelle erstes Ticket",
            "title": "Tickets erstellen"
          },
          "actions": {
            "createShop": "Shop erstellen",
            "copyShop": "Shop kopieren",
            "newShop": "Neuer Shop",
            "editShop": "Shop bearbeiten",
            "createGroup": "Gruppe erstellen",
            "editGroup": "Gruppe bearbeiten",
            "createNewPool": "Pool erstellen",
            "addTicket": "Ticket erstellen",
            "addRegular": "Ticket",
            "addCombi": "Combi-Ticket",
            "addUpsell": "Upsell",
            "addGuestVariant": "Gästeliste",
            "addGroup": "Gruppe erstellen",
            "saveLayout": "Layout speichern",
            "saveDraft": "Als Entwurf speichern",
            "addField": "Neue Zeile hinzufügen",
            "saveTemplate": "Vorlage speichern"
          },
          "group": {
            "ticketPlaceholder": "Ziehe ein Ticket hierher, um Gruppe zu erstellen",
            "collapseCheckbox": "Standardmäßig einklappen",
            "messagePlaceholder": "Gruppiere Nachrichten auf der Ticket-Liste...",
            "defaultTitle": "Neue Gruppe"
          },
          "blocksHelper": {
            "title": "Möchtest du die Anzahl der Blöcke bearbeiten? {0}",
            "link": "Zur Blockierung von Kategorien"
          },
          "gates": {
            "title": "Dieser Shop ist über die folgenden Gates (Links) erreichbar",
            "description": "Gates können mehrere Shops über verschiedene Veranstaltungen hinweg halten, von denen der Benutzer wählen kann. Standardmäßig hat jeder ein eigenes (einzelnes Gate).",
            "localGate": "Dieser Shop",
            "defaultGateError": "Jeder Shop muss ein Standard-Gate haben. Bitte lege ein Standard-Gate fest, indem Du auf einen der Sterne klickst.",
            "defaultTooltip": "Dieses Gate standardmäßig verwenden, wenn Du zu diesem Shop verlinkst"
          }
        },
        "checkin": {
          "title": "Self check-in",
          "description": "Scan the QR code to check-in attendees",
          "waiting": "Waiting for QR code...",
          "AllClaimed": "All claimed",
          "checkin": "Check-in {amount} ticket(s)",
          "success": "{amount} ticket(s) checked in succesfully!",
          "errors": {
            "noPrivileges": "This QR code does not contains tickets",
            "wrongEvent": "The QR code belongs to a different event"
          }
        },
        "pools": {
          "title": "Pools",
          "addNew": "Pool erstellen",
          "notFound": "Pool nicht gefunden...",
          "poolSelection": "Wähle einen Pool oder mehrere Pools aus, um ein Ticket zu erstellen. Für Up-Selling-Produkte verwende die Up-Selling-Pools. Wenn du den Pool, den du suchst, nicht findest, kannst du",
          "createNewPool": "Erstelle eine neue.",
          "guestTicket": "Dies ist ein Gastticket",
          "toolTipEdititon": "Dieser Pool kann nicht bearbeitet werden",
          "poolModal": {
            "ticketPrivilege": "ticket-privileges",
            "privilege": "privilege",
            "entrance": "Entrance",
            "upsell-text": "Upsell",
            "create": "Pool erstellen",
            "description": "A privilege pool is used to determine the total number of so called {ticketPrivileges} (or {privilege} in short), that can be issued. A privilege is of the type {Entrance} or {Upsell}. 1x capacity equals 1 privilege.",
            "update": "Pool bearbeiten",
            "name": "Pool Name",
            "validFrom": "Gültig ab",
            "validTo": "Gültig bis",
            "blocks": "Blöcke (abgezogen von gesamt)",
            "totalAmoutOfTickets": "Gesamtzahl der Tickets",
            "blockHelperText": "Du kannst später immer die Anzahl der Blöcke und Kategorien bearbeiten.",
            "poolNameHelperText": "For instance 'VIP' or 'T-Shirt'",
            "totalCapacityHelperText": "Maximum capacity to be issued",
            "upsell": "Verwende dies für Up-Selling-Produkte. Die Verfügbarkeit der Tickets wird von den Produkten nicht beeinflusst.",
            "deleteMessage": "Bist du sicher, dass du diesen Pool löschen möchtest?",
            "deleteTitle": "Löschen",
            "privilegeType": "Privilege type",
            "tellMeMore": "Tell me more",
            "listItem1": "Use to grant access to an event (or livestream)",
            "listItem2": "Total amount of tickets a user can get of this type can be limited per shop ",
            "listItem3": "Use for additional services/products (like drinks/merchandise)",
            "listItem4": "Does not count towards the users' amount of tickets limited per shop"
          },
          "details": {
            "sold": "Verkauft",
            "tickets": "Tickets",
            "shop": "Shop",
            "price": "Preis"
          }
        }
      }
    },
    "reservations": {
      "addTickets": "Add tickets",
      "reservationsOverviewTitle": "Your reservations",
      "details": {
        "editTitle": "Edit reservation details"
      },
      "cancel": {
        "cancelTitle": "Cancel reservation",
        "cancelDescription": "Are you sure you want to cancel the reservation? This cannot be undone.",
        "yesCancelReservation": "Yes, cancel reservation",
        "blockTickets": "Block tickets that are available again",
        "blockTicketsDescription": "If you don't select this option, tickets will be up for sale again"
      },
      "confirm": {
        "title": "Confirm tickets",
        "descriptions": {
          "paidConfirmAll": "Are you sure you want to confirm the sale of all tickets in this reservation? Once confirmed, you'll be able to distribute the tickets. Please specify the payment method:",
          "freeConfirmAll": "Are you sure you want to confirm the sale of all tickets in this reservation? Once confirmed, you'll be able to distribute the tickets.",
          "paidConfirmPartial": "Are you sure you want to confirm the sale of the selected tickets in this reservation? Once confirmed, you'll be able to distribute the tickets. Please specify the payment method:",
          "freeConfirmPartial": "Are you sure you want to confirm the sale of the selected in this reservation? Once confirmed, you'll be able to distribute the tickets."
        }
      },
      "release": {
        "action": "Release",
        "title": "Release tickets",
        "description": "Are you sure you want to release the selected tickets from the reservation?"
      },
      "distributionModal": {
        "title": "Distribute tickets ({count})",
        "description": "Fill the email addresses of people to whom you want to distribute the selected tickets.",
        "allToOne": "All tickets to one person",
        "invitationEmail": "Invitation email address",
        "confirmText": "Send invitations"
      },
      "distribution": {
        "title": "Distribute Tickets",
        "emails": {
          "title": "Distribute tickets to the following email addresses",
          "info": "You can also enter multiple entries separated by a space, comma, or semi-colon.",
          "duplicates": {
            "title": "Duplicates",
            "message": "The following duplicate emails were not added to the list: <strong>{list}</strong>"
          },
          "errors": {
            "exceedsMax": "{count} out of {max} people added. Remove {remove_count} entries or reduce the number of tickets to distribute per person.",
            "validation": "One or more added email addresses are not valid, please correct them!"
          },
          "entriesLeft": "{count} entries left"
        },
        "tickets": {
          "title": "Each entry will receive the following tickets",
          "ticketLeft": "ticket left | tickets left"
        },
        "send": {
          "title": "Send invitations",
          "summary": {
            "title": "Summary",
            "description": "Each invitation contains the following ticket(s):"
          },
          "extraMessage": {
            "title": "Personal message",
            "description": "If you want you can add a short extra message to the ticket invitation email."
          },
          "sendAction": "Send invitations",
          "success": "Successfully created and sent out invitations!",
          "errors": {
            "create": "Failed to create invitations!",
            "send": "Failed to send invitations"
          }
        },
        "resend": {
          "title": "Resend invitation",
          "areYouSure": "Are you sure want to resend this invite?",
          "description": "We'll send another invitation to \"{email}\"",
          "error": "Failed to send invitation!",
          "success": "Succesfully sent out invitation!"
        },
        "revoke": {
          "title": "Revoke invitation",
          "areYouSure": "Are you sure want to revoke this invite?",
          "description": "After revoking, the tickets will be available again in the reservation to distribute.",
          "error": "Failed to revoke invitation!",
          "success": "Succesfully revoked invitation!"
        }
      }
    },
    "scanning": {
      "selectOrganisation": "Wähle eine Organisation aus, um die Anmeldedaten einsehen zu können",
      "selectOrganisationMessage": "Bitte eine Organisation auswählen",
      "organisations": "Organisationen",
      "scannerInfo": "Verwende die untenstehenden Informationen, um dich beim Scanner anzumelden. Bitte beachte, dass diese Login-Informationen über die Organisation verteilt werden und nicht für eine Event spezifisch sind.",
      "downloadInfo": "GET-In-App auf dein Smartphone herunterladen:",
      "passwordInfo": "Benutze das Passwort oder scanne den QR-Code zum Anmelden",
      "noOrganisationSelected": "Du hast noch keine Organisation ausgewählt."
    },
    "navigation": {
      "eventPreview": {
        "label": "Entwurfsvorlage anzeigen",
        "title": "Vorschau deines Events",
        "content": "Schau dir deine Event an und kaufe Test-Tickets , um sicherzustellen, dass alles richtig eingerichtet ist.<br><br> Diese Vorschau zeigt genau wie deine Besucher den Fluss erleben werden, mit Ausnahme der Zahlung, die im Vorschaumodus einen Testzahlungs-Anbieter verwendet, so dass du Test-Tickets kaufen können.<br><br>Test-Tickets bleiben in deinem Konto, werden aber keine tatsächlichen Verkäufe und Statistiken beeinflussen."
      },
      "eventUrlInfo": "Deine Event wird nach der Veröffentlichung auf dieser URL verfügbar sein",
      "back": "Zurück zu allen meinen Events"
    },
    "prices": {
      "title": "Ihre Preise",
      "addPrice": "Preis hinzufügen",
      "editPrice": "Preis bearbeiten",
      "editOverviewDescription": "Die folgenden Event und Tickets haben den gleichen Preis. Die Bearbeitung dieses globalen Preises wirkt sich auf alle aus. Wenn du fortfahren möchtest, klicke auf Bestätigen.",
      "table": {
        "name": "Preisname",
        "amount": "Betrag",
        "usedBy": "Verwendet von"
      },
      "priceSelect": {
        "noAvailable": "Es gibt keine globalen Preise für die Organisation dieser Event."
      }
    },
    "gates": {
      "title": "Deine Gates",
      "open": "Eröffnungsdatum",
      "close": "Enddatum",
      "slug": "URL Slug",
      "create": "Gate erstellen",
      "gate": {
        "description": "Dieses Gate ist zugänglich, wenn der erste Shop geöffnet wird <strong>{start}</strong> bis das letzte Event in diesem Gate endet <strong>{ends}</strong>.",
        "descriptionPrequeue": "Die Warteschlange ist <strong>aktiviert</strong>.",
        "descriptionAccess": "Zugriffslinks sind <strong>aktiviert</strong>.",
        "gateLinks": {
          "title": "Links & Einbettungscodes für den Shop",
          "linkInfo": "Benutze diesen Link immer, wenn Du die URL teilst - Kopiere die URL nicht aus dem Browser.",
          "link": "Direkter Link zu diesem Gate",
          "embed": "Code dieses Gates einbetten"
        },
        "accessLinks": {
          "nav": "Zugriffslinks",
          "title": "Zugangslinks generieren",
          "description": "Gebe die gewünschte Anzahl von Zugangslinks ein und klicke auf Exportieren als csv. Die folgenden Links sind <strong>Beispiellinks</strong>.",
          "action": "Export as csv"
        }
      },
      "gateSelect": {
        "noAvailable": "Es gibt keine Gates für diese Organisation. Du kannst ein neues erstellen."
      },
      "shops": {
        "pastLabel": "Vergangene Veranstaltungen anzeigen",
        "searchPlaceholder": "Nach Events oder Shops suchen...",
        "nonGateShopsTitle": "Alle Events & Shops",
        "gateShopsTitle": "Shops in diesem Gate ({count})",
        "addToGate": "Shop hinzufügen ({count}) | ({count}) Shops hinzufügen",
        "removeFromGate": "Entferne ({count}) Shop | Entferne ({count}) Shops",
        "selectInfo": "To select multiple shops, you can press shift or control (command on Mac) and select, or click and drag."
      },
      "modal": {
        "create": "Create gate",
        "edit": "Edit gate",
        "optionalSlug": "Slug (optional)",
        "nameDescription": "This name is only used in dashboard as an identifier",
        "delete": {
          "title": "Delete gate",
          "description": "Are you sure you want to delete this gate? If yes, click confirm."
        },
        "display": {
          "title": "Display",
          "description": "Select how you want to display your events:",
          "eventList": "Show events in list",
          "calendar": "Show events in calendar",
          "override": "Override event information",
          "overrideDescription": "By default, this information is set automatically based on the events added to this gate."
        },
        "access": {
          "title": "Access",
          "accessLinks": "Requires access links",
          "accessLinksDescription": "This will make this gate accessible only for people with an access-link",
          "prequeue": "Enable prequeue",
          "prequeueDescription": "This allows users to register in the queue before the sale starts"
        }
      }
    },
    "templates": {
      "title": "Your templates",
      "createTemplate": "Vorlage erstellen",
      "selectTemplate": "Vorlage auswählen",
      "noAvailable": "Keine Vorlage verfügbar",
      "templateNotFound": "Template nicht gefunden...",
      "status": {
        "syncing": "Synchronisieren...",
        "unsynced": "Nicht synchronisiert",
        "synced": "Alle synchronisiert"
      },
      "sync": {
        "title": "Änderungen der Vorlagen an anstehenden Eventn synchronisieren",
        "description": "Du hast Änderungen an dieser Vorlage vorgenommen. Um alle Events, die diese Vorlage verwenden, zu aktualisieren, kannst du deine Änderungen hier synchronisieren. Denk daran, dass <b>alle vorgenommenen Änderungen</b> auf <b>alle bevorstehenden Event</b> angewendet werden, die mit dieser Vorlage erstellt wurden.",
        "action": "Sync-Änderungen",
        "syncNow": "Jetzt synchronisieren",
        "changesApplied": "Deine Änderungen werden auf diese {0} anstehenden Event angewendet",
        "changesApplying": "Alle {0} anstehenden Events sind synchronisiert!",
        "eventsSyncing": "Synchronisierung der laufenden Event...",
        "SyncStarted": "Sync gestartet am {date}",
        "lastSync": "Zuletzt synchronisiert am {date}",
        "errors": {
          "nothingToSync": "Diese Vorlage ist bereits komplett synchronisiert",
          "beingSynced": "Diese Vorlage wird bereits synchronisiert"
        },
        "warningModal": {
          "description": "Alle Änderungen in dieser Vorlage werden mit allen anstehenden Eventn synchronisiert, die von dieser Vorlage verwaltet werden. Es gibt ein paar Regeln, die angewendet werden könnten, um Fehler zu vermeiden:",
          "lowerCapacity": "Wenn du die Kapazität der Pools verringert hast, aber die Event(en) haben bereits mehr Tickets verkauft als die nun verringerte Kapazität dann wird die Poolgröße für dieses Event auf das gesetzt, was derzeit verkauft wird. Zum Beispiel: Die Kapazität von 100 auf 50 - bereits verkaufte 60 - Poolgröße für diese Event wird auf 60 gesetzt.",
          "removeTickets": "Wenn du Tickets entfernt hast, die bereits für bestimmte Events verkauft sind diese Tickets werden nicht aus diesen speziellen Eventn entfernt, sondern stattdessen in den Shops versteckt.",
          "removeShop": "Wenn du einen Shop entfernt hast, aber bestimmte Event noch Tickets in diesem speziellen Shop haben, dann wird dieser Shop nicht von diesem Ereignis entfernt, sondern geschlossen."
        }
      },
      "templateAlert": "Bitte beachten! Wenn du Änderungen vornehmen möchtest, werden auch alle Event im Zusammenhang mit dieser Vorlage aktualisiert.",
      "createFromTemplate": {
        "title": "Ereignis aus Vorlage erstellen",
        "createInfo": "Alle Template-Konfigurationen werden zu diesem Ereignis kopiert.",
        "templateInfo": {
          "text": "Wenn du die gewünschte Vorlage nicht finden kannst, kannst du eine neue {link} erstellen",
          "link": "hier."
        },
        "timeInfo": "Lokale Zeit: {timezone}, {time}. Die Zeit des Events ist aus der Vorlage konfiguriert.",
        "errors": {
          "templateBeingSynced": "Du kannst kein Ereignis aus dieser Vorlage erstellen, weil es synchronisiert wird.",
          "templateNotSynced": "Du kannst kein Ereignis aus dieser Vorlage erstellen, da es nicht synchronisiert wurde."
        }
      }
    },
    "schedules": {
      "title": "Your schedules",
      "create": {
        "title": "Zeitplan erstellen"
      },
      "schedulePatterns": {
        "description": "Die folgenden Vorlagen werden verwendet, um mehrere Event für den von Ihnen angegebenen Zeitraum zu generieren.",
        "action": "Vorlage hinzufügen",
        "days": "Tage",
        "exceptions": "Ausnahmen",
        "modal": {
          "addTemplate": "Wähle die Vorlage, um dem Zeitplan hinzuzufügen",
          "edit": "Zeitplan bearbeiten",
          "pattern": "An welchen Wochentagen sind die Events?",
          "exception": "Ausnahmedatum hinzufügen (optional)",
          "addException": "Add exception"
        }
      },
      "entries": {
        "startDate": "Startdatum (inklusive)",
        "endDate": "Enddatum (inklusive)",
        "description": "Erzeuge neue Events aus den konfigurierten Vorlagen, indem du den ersten Tag und die Anzahl der folgenden Tage angibst. Ausnahmedaten werden übersprungen.",
        "generate": "Event generieren",
        "syncWarning": "Event können nicht generiert werden, da eine oder mehrere Vorlagen nicht synchronisiert sind! Bitte synchronisiere deine Vorlagen zuerst, bevor du neue Events erstellst.",
        "newItemStatus": "{patternName} Event",
        "confirmDescription": "Die folgenden Events werden generiert und veröffentlicht:",
        "confirmContinue": "Wenn du fortfahren möchtest, klicke auf Bestätigen.",
        "rangeAlert": "You can only generate maximum 1 year ahead at once. The enddate should be before {maxDate}.",
        "countAlert": "You are about to create {count} new events at once. Are you sure?",
        "tooltip": {
          "existing": "Existing",
          "generate": "To generate"
        }
      }
    },
    "cashier": {
      "filters": {
        "available": "Available",
        "blocks": "Blocks",
        "fromBlocks": "From blocks",
        "price": "Price",
        "type": "Type",
        "ticket": "Ticket",
        "upsell": "Upsell",
        "shop": "Shop",
        "pool": "Pool",
        "lockedShop": "You can only select tickets from {shopName} shop. To select a different shop you will have to empty your cart first.",
        "lockedShopReservation": "You can only select tickets from {shopName} shop. to select a different shop you will have to create additional reservation."
      },
      "abortAction": {
        "title": "Cancel order",
        "description": "Are you sure you want to cancel this order? All tickets in the cart will be removed and you will not be able to undo this action.",
        "confirm": "Yes, cancel this order"
      },
      "removeTickets": {
        "title": "Remove {ticketName} tickets",
        "description": "How many {ticketName} tickets do you want to remove?",
        "max": "max"
      },
      "removeSeatedTickets": "Which tickets do you want to remove?",
      "removeAll": {
        "title": "Remove all",
        "description": "Do you want to remove all tickets from the cart?",
        "confirm": "Remove all"
      },
      "removeOne": {
        "title": "Remove ticket",
        "description": "Are you sure you want to remove this ticket?",
        "confirm": "Remove ticket"
      },
      "confirmationModal": {
        "titleOrder": "Create order",
        "titleReservation": "Create reservation",
        "description": {
          "detailsOnly": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will not receive any confirmation.",
          "detailsAndSMS": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive a confirmation of the purchase via SMS.",
          "detailsAndEmail": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive an email confirmation with the event & ticket information.",
          "detailsSMSAndEmail": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive a confirmation of the purchase via SMS and an email confirmation with the event & ticket information.",
          "onlyEmail": "Tickets are not linked to the customers account because no phone number was provided. If you want to give the customer the tickets, you need to print the order. The customer will not receive any confirmation.",
          "onlySMS": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive a confirmation of the purchase via SMS.",
          "onlyNumber": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will not receive any confirmation."
        },
        "notifySMS": "Notify via SMS",
        "notifyEmail": "Notify via email",
        "reservation": {
          "name": "Reservation name",
          "note": "Note",
          "expiry": "Expiry"
        }
      },
      "complete": "Complete order"
    }
  },
  "errors": {
    "not-found": {
      "title": "Can’t access this page",
      "description": "You either do not have permission to view this page or it simply does not exist.",
      "contactSupport": "If you believe this to be an error, contact the organisation you trying to access, or {0} for help.",
      "link": "contact support"
    }
  },
  "filter": {
    "filterBy": "Filter by {name}",
    "operators": {
      "e": "Equals to",
      "lte": "Less than or equal to",
      "gte": "Greater than or equal to",
      "lt": "Less than",
      "gt": "Greater than"
    },
    "timeOperators": {
      "last": "Is in the last",
      "equal": "Is equal to",
      "between": "Is between",
      "after": "Is after",
      "onafter": "Is on or after",
      "before": "Is before",
      "onbefore": "Is before or on"
    },
    "times": {
      "days": "Days",
      "weeks": "Weeks",
      "months": "Months",
      "years": "Years"
    },
    "aria": {
      "open": "Open filter menu",
      "close": "Close filter menu",
      "remove": "Remove filter",
      "add": "Add {name} filter",
      "openAdd": "Open add filter menu",
      "clearAll": "Clear all filters"
    },
    "addFilter": "Add filter",
    "clearAll": "Clear all",
    "select": "Select",
    "selectAll": "Select all",
    "between": "And"
  },
  "resaleFeeConfig": {
    "sellerTitle": "Seller fees",
    "buyerTitle": "Buyer fees",
    "fixedTitle": "Fixed fees",
    "fixedLabel": "Fixed fee per ticket",
    "percTitle": "Percent fees",
    "percLabel": "Percent fee per ticket",
    "totalLabel": "Total fee",
    "getFeeLabel": "Base - GET International",
    "partnerFeeLabel": "Ticketeer fee",
    "override": {
      "label": "Ticketeer fee override",
      "confirm": {
        "title": "Override Ticketeer default fees",
        "description": "Are you sure you want to override the resale market fees for this organization? Any changes to the resale market fee plans will only apply for newly published events. Events that are currently on sale will retain the current fee configuration."
      }
    }
  }
}
