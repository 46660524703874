const Exports = () => import('@/views/events/event/reports/exports/index.vue');
const Export = () => import('@/components/exports/Export.vue');
const AdvancedExport = () => import('@/views/events/event/reports/exports/AdvancedExport.vue');

export default {
  path: 'exports',
  name: 'exports',
  component: Exports,
  redirect: { name: 'event-export-summary' },
  meta: {
    auth: true,
    org: true,
    permissions: {
      access: ({ state, getters }, _) => {
        const { current_event } = state.Events;
        const hasPermission =
          getters['Events/getPermission']('reports.summary.export') ||
          getters['Events/getPermission']('reports.revenue.export') ||
          getters['Events/getPermission']('reports.attendees.export') ||
          getters['Events/getPermission']('reports.blocks.export') ||
          getters['Events/getPermission']('reports.groups.export') ||
          getters['Events/getPermission']('reports.orders.export') ||
          getters['Organizations/getTicketeersPermission']('reports.ticketsales.export') ||
          getters['Organizations/getTicketeersPermission']('reports.ticketresales.export') ||
          getters['Organizations/getTicketeersPermission']('reports.billing.export');
        return current_event && !current_event.is_template && hasPermission;
      },
      redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
    },
  },
  children: [
    {
      path: 'summary',
      name: 'event-export-summary',
      component: Export,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ state, getters }, _) => {
            const { current_event } = state.Events;
            const hasPermission = getters['Events/getPermission']('reports.summary.export');
            return current_event && !current_event.is_template && hasPermission;
          },
          redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    {
      path: 'revenue',
      name: 'event-export-revenue',
      component: Export,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ state, getters }, _) => {
            const { current_event } = state.Events;
            const hasPermission = getters['Events/getPermission']('reports.revenue.export');
            return current_event && !current_event.is_template && hasPermission;
          },
          redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    {
      path: 'blocks',
      name: 'event-export-blocks',
      component: Export,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ state, getters }, _) => {
            const { current_event } = state.Events;
            const hasPermission = getters['Events/getPermission']('reports.blocks.export');
            return current_event && !current_event.is_template && hasPermission;
          },
          redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    {
      path: 'groups',
      name: 'event-export-groups',
      component: Export,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ state, getters }, _) => {
            const { current_event } = state.Events;
            const hasPermission = getters['Events/getPermission']('reports.groups.export');
            return current_event && !current_event.is_template && hasPermission;
          },
          redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    {
      path: 'attendees',
      name: 'event-export-attendees',
      component: Export,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ state, getters }, _) => {
            const { current_event } = state.Events;
            const hasPermission = getters['Events/getPermission']('reports.attendees.export');
            return current_event && !current_event.is_template && hasPermission;
          },
          redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    {
      path: 'orders',
      name: 'event-export-orders',
      component: Export,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ state, getters }, _) => {
            const { current_event } = state.Events;
            const hasPermission = getters['Events/getPermission']('reports.orders.export');
            return current_event && !current_event.is_template && hasPermission;
          },
          redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    {
      path: 'advanced',
      name: 'event-export-advanced',
      component: AdvancedExport,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ state, getters }, _) => {
            const { current_event } = state.Events;
            const hasPermission =
              getters['Organizations/getTicketeersPermission']('reports.ticketsales.export') ||
              getters['Organizations/getTicketeersPermission']('reports.ticketresales.export') ||
              getters['Organizations/getTicketeersPermission']('reports.billing.export');
            return current_event && !current_event.is_template && hasPermission;
          },
          redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
        },
      },
    },
  ],
};
