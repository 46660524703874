{
  "countries": {
    "selectCountry": "Select country",
    "AF": "Afghanistan",
    "AX": "Åland Islands",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia (Plurinational State of)",
    "BQ": "Bonaire, Sint Eustatius and Saba",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "CV": "Cabo Verde",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo",
    "CD": "Congo (Democratic Republic of the)",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "CI": "Côte d'Ivoire",
    "HR": "Croatia",
    "CU": "Cuba",
    "CW": "Curaçao",
    "CY": "Cyprus",
    "CZ": "Czechia",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (Malvinas)",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island and McDonald Islands",
    "VA": "Holy See",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran (Islamic Republic of)",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea (Democratic People's Republic of)",
    "KR": "Korea (Republic of)",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People's Democratic Republic",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MK": "Macedonia (the former Yugoslav Republic of)",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia (Federated States of)",
    "MD": "Moldova (Republic of)",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestine, State of",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Réunion",
    "RO": "Romania",
    "RU": "Russian Federation",
    "RW": "Rwanda",
    "BL": "Saint Barthélemy",
    "SH": "Saint Helena, Ascension and Tristan da Cunha",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint Martin (French part)",
    "PM": "Saint Pierre and Miquelon",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome and Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten (Dutch part)",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "SS": "South Sudan",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard and Jan Mayen",
    "SZ": "Swaziland",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan, Province of China",
    "TJ": "Tajikistan",
    "TZ": "Tanzania, United Republic of",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom of Great Britain and Northern Ireland",
    "US": "United States of America",
    "UM": "United States Minor Outlying Islands",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela (Bolivarian Republic of)",
    "VN": "Viet Nam",
    "VG": "Virgin Islands (British)",
    "VI": "Virgin Islands (U.S.)",
    "WF": "Wallis and Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
  },
  "placeholders": {
    "dateTime": "dd-mm-yyyy hh:mm",
    "date": "dd-mm-yyyy",
    "time": "hh:mm",
    "search": "Cerca...",
    "searchCountryCode": "Search for country code",
    "loading": "Caricamento...",
    "email": "example{'@'}mail.org",
    "uploadImagePlaceholder": "Seleziona un'immagine o trascinala qui.",
    "dropImagePlaceholder": "Trascina la tua immagine qui.",
    "selectOrganisationMessage": "Seleziona un'organizzazione",
    "selectGate": "Search or select gate...",
    "selectRole": "Select role",
    "selectLocale": "Select language",
    "selectTicketeer": "Select ticketeer",
    "phoneNumber": "e.g. +141566573212"
  },
  "intervals": {
    "hour": "Per hour",
    "day": "Per day",
    "week": "Per week",
    "month": "Per month",
    "year": "Per year"
  },
  "permissions": {
    "noPermissions": "Non hai i permessi necessari",
    "contactAdministrator": "Contatta il tuo amministratore per i permessi necessari",
    "insufficientPermissions": "Permessi insufficienti"
  },
  "notifications": {
    "saveSuccess": "Salvato con successo",
    "savePublishSuccess": "Salvato e pubblicato con successo",
    "removeSuccess": "Rimosso con successo",
    "saveFail": "Salvataggio fallito",
    "removeFail": "Rimozione fallita",
    "cashierCartExpired": "Your cart has expired due to inactivity. Please reload the page to start over.",
    "cartUpdate": {
      "edit": {
        "success": "The cart has been updated successfully.",
        "fail": "Failed to update the cart."
      },
      "add": {
        "success": "The ticket(s) have been added successfully.",
        "fail": "Failed to add ticket(s)."
      },
      "remove": {
        "success": "The ticket(s) have been removed successfully.",
        "fail": "Failed to remove ticket(s)."
      }
    },
    "genericError": "Errore",
    "successUpdate": "Successfully updated",
    "successEmail": "Email sent",
    "successShared": "Ticket shared",
    "successDownload": "File downloaded",
    "successPassword": "Password is successfully reset",
    "copiedToClipboard": "Copiato negli appunti!",
    "copyFailed": "Failed to copy to your clipboard",
    "invitationSent": "Invitation sent",
    "notEnoughPrivs": "Errore: impossibile bloccare questa quantità di biglietti, dato che non ce ne sono più così tanti disponibili. Puoi provare aumentando il numero di biglietti.",
    "unSavedAlert": {
      "title": "Hai delle modifiche non salvate",
      "description": "Sei sicuro di voler uscire senza salvare?"
    },
    "TicketKindExists": "Non puoi rimuovere questa categoria perché ha dei biglietti associati",
    "TicketKindExistsInShop": "Non puoi rimuovere questo negozio perché ha dei biglietti associati",
    "anonymizeUserSuccess": "The account data has been anonymized.",
    "anonymizeUserFail": "An error occurred while removing the account, the user is probably in the process of purchasing a ticket. Please try again later.",
    "feature_flag": "This feature is available for users with 'Tester' permissions only.",
    "tasks": {
      "taskLabel": "Task:",
      "stateLabel": "State:",
      "states": {
        "processing": "Processing",
        "not_started": "Not started",
        "failed": "Failed",
        "completed": "Completed"
      },
      "event_copy": {
        "title": "Copy event",
        "taskDescription": "Copying {title}",
        "link": "Go to the new event"
      },
      "shop_copy": {
        "title": "Copy Shop",
        "taskDescription": "Copying Shop {title}"
      },
      "template_generate": {
        "title": "Generating event",
        "taskDescription": "Generating from template:",
        "link": "Go to the new event"
      },
      "template_copy": {
        "title": "Copy template",
        "taskDescription": "Copying Template {title}"
      }
    }
  },
  "navigation": {
    "home": "Home",
    "events": "Eventi",
    "customers": "Customers",
    "upcoming": "Prossimi",
    "past": "Precedente",
    "draft": "Bozza",
    "reports": "Reports",
    "checkIns": "Convalide",
    "sales": "Vendite",
    "exports": "Exports",
    "manage": "Gestisci",
    "blocks": "Blocchi",
    "general": "Generale",
    "eventMarketing": "Marketing",
    "eventAdvanced": "Advanced",
    "eventBlockchain": "Blockchain",
    "resaleFees": "Resale fees",
    "scanning": "Scansione",
    "seating": "Posti a sedere",
    "external": "Esterno",
    "demographics": "Demografiche",
    "pools": "Tipologie",
    "shops": "Negozio | Negozi",
    "prices": "Prices",
    "gates": "Gates",
    "templates": "Templates",
    "schedules": "Scheduler",
    "sync": "Sync changes",
    "organizations": "Organisations",
    "checkin": "Check-in",
    "orders": "Orders",
    "reservations": "Reservations"
  },
  "actions": {
    "accept": "Accept",
    "seeAll": "See all",
    "ok": "OK",
    "close": "Chiudi",
    "logout": "Disconnetti",
    "login": "Accedi",
    "signup": "Sign up",
    "publish": "Pubblica",
    "savePublish": "Salva e pubblica",
    "confirm": "Conferma",
    "confirmAll": "Confirm all",
    "saveChanges": "Save changes",
    "invalidate": "Invalida",
    "reset": "Ripristina",
    "export": "Esporta",
    "loadMore": "Carica di più",
    "cancel": "Annulla",
    "search": "Cerca",
    "copy": "Copia",
    "apply": "Applica",
    "edit": "Modifica",
    "back": "Back",
    "save": "Salva",
    "delete": "Cancella",
    "preview": "Anteprima",
    "next": "Prosegui",
    "uploadImage": "Carica immagine",
    "checkin": "Convalida",
    "showAll": "Mostra tutti",
    "clear": "Clear",
    "solve": "Assegnazione automatica posto",
    "finalize": "Concludi",
    "assign": "Assegna",
    "printTickets": "Stampa biglietto | Stampa biglietti",
    "printPrivileges": "Print privilege | Print privileges",
    "printAllPrivilegesforCustomer": "Print all tickets",
    "print": "Print",
    "block": "Blocco",
    "unblock": "Pubblica",
    "addRow": "Aggiungi riga",
    "email": "Email",
    "sendEmail": "Send Email",
    "resend": "Resend",
    "revoke": "Revoke",
    "editPhoneNumber": "Edit Phone Number",
    "deleteAccount": "Delete Account",
    "sellTickets": "Sell Tickets",
    "batchAction": "Azione multipla",
    "import": "Importa",
    "browse": "Naviga",
    "selectTimezone": "Seleziona un fuso orario",
    "chooseFile": "Choose a file or drop it here",
    "linkEmbed": "Link/embed",
    "view": "Visualizza",
    "click": "clicca qui",
    "checkout": "Annulla Check In",
    "add": "Add",
    "remove": "Remove",
    "removeAll": "Remove all",
    "change": "Change",
    "activate": "Activate",
    "deactivate": "Deactivate",
    "toDashboard": "Continue to dashboard",
    "switchOrganization": "Switch organization",
    "allOrganizations": "Show from all my organisations",
    "goToEvents": "Go to my events",
    "clearFile": "Clear file",
    "download": "Download",
    "help": "Help",
    "multiNoteTicket": "Multiple notes. Click the ticket for details.",
    "multiSeatingTicket": "Multiple seatings. Click the ticket for details.",
    "createGroup": "Crea gruppo",
    "select": "Select",
    "issue": "Issue",
    "issueNow": "Issue now",
    "createReservation": "Create Reservation",
    "update": "Update",
    "distributeTickets": "Distribute tickets"
  },
  "labels": {
    "en": "English",
    "nl": "Nederlands",
    "de": "German",
    "it": "Italiano",
    "ko": "한국어",
    "es": "Español",
    "locale": "Language",
    "yes": "Yes",
    "no": "No",
    "name": "Nome",
    "shortName": "Short name",
    "date": "Data",
    "time": "Ora",
    "id": "ID",
    "checkinOptions": {
      "all": "All",
      "blocked": "Bloccato",
      "assigned": "Assegnato"
    },
    "amountSpecifier": "of",
    "ticketeer": "Ticketeer",
    "currentOrg": "Current organisation",
    "events": "Evento | Eventi",
    "eventTitle": "Nome evento | Nome eventi",
    "order": "Order | Orders",
    "reservation": "Reservation",
    "created": "Creato",
    "expiry": "Expiry",
    "expired": "Expired",
    "claimed": "Claimed",
    "open": "Open",
    "canceled": "Canceled",
    "totalValue": "Total Value",
    "paidValue": "Paid value",
    "completed": "Completed",
    "actions": "Actions",
    "payment": "Payment | Payments",
    "method": "Method | Methods",
    "orderId": "Order-ID {id}",
    "paymentId": "Payment-ID {id}",
    "template": "Template | Templates",
    "templateTitle": "Template name",
    "organization": "Organizzazioni",
    "section": "Sezione",
    "enabled": "enabled",
    "disabled": "disabled",
    "state": "State",
    "success": "Success!",
    "danger": "Error",
    "credits": "Credits",
    "summary": "Summary",
    "paymentMethod": "Payment Method | Payment methods",
    "pspId": "Psp ID",
    "type": "Type",
    "left": "Left",
    "max": "Max",
    "label": "{options} and {length} more",
    "notAvailableShort": "N/A",
    "availability": "Availability",
    "privilege": "Privilege | Privileges",
    "category": "Category | Categories",
    "yourOrders": "Your orders",
    "yourCustomers": "Your customers",
    "yourShops": "Your shops",
    "contactName": "Contact name",
    "contactEmail": "Contact email",
    "contactPhone": "Contact phone",
    "email": "Email address",
    "createdOn": "Created on",
    "createdBy": "Created by",
    "yourReservations": "Your reservations",
    "reservationId": "Reservation-ID {id}",
    "invitations": "Invitation | Invitations",
    "recipient": "Recipient",
    "claimedBy": "Claimed by",
    "dates": {
      "today": "Today",
      "yesterday": "Yesterday",
      "last7Days": "Last 7 days",
      "last4Weeks": "Last 4 weeks",
      "thisMonth": "This month",
      "thisYear": "This year",
      "custom": "Custom",
      "allTime": "All time",
      "endDate": "End date",
      "startDate": "Start date",
      "fromDate": "From date",
      "untilDate": "Until date (included)",
      "years": "year | years",
      "am": "AM",
      "pm": "PM",
      "timezone": "Time zone",
      "validFrom": "Valid from",
      "validUntil": "Valid until",
      "dateOptions": {
        "dateAndTime": "Date & time",
        "specificDate": "on a specific day and time",
        "whenShopOpens": "when the shop opens",
        "relativeDateEnds": "on relative time before the event ends",
        "relativeDateStarts": " on relative time before the event starts",
        "whenEventEnds": "when the event ends",
        "whenDoorsOpen": "when the doors open",
        "doorsOpenWarning": "Your event starts on {eventDateTime}. The customers' tickets QR code will become available in their app 3 hours before the doors open.",
        "immediately": "Immediately",
        "absoluteTimeWarning": "Warning: Created events from a template, or copied events, will use this specific date, even when the newly created event's date is different.",
        "relativeTimeWarningStart": {
          "cta": "Enter the amount of days before the event starts and the time on that day to use this relative date.",
          "warning": "If your event starts on {eventStarts} the actual date would be the {selectedDateTime}"
        },
        "relativeTimeWarningEnd": {
          "cta": "Inserisci la quantità di giorni prima della fine dell'evento e l'ora di quel giorno per utilizzare questa data relativa.",
          "warning": "If your event ends on {eventEnds} the actual date would be the {selectedDateTime}"
        },
        "relativeTimeInfo": "Inserisci la quantità di giorni prima della fine dell'evento e l'ora di quel giorno per utilizzare questa data relativa.",
        "relativeTimeExample": "If your event ends on {eventEnds} the actual date would be the {datetime}.",
        "ticketRelativeTimeFromInfo": "Enter the amount of days before the event ends and the time on that day to set the moment when these tickets become available at the shop.",
        "ticketRelativeTimeToInfo": "Enter the amount of days before the event ends and the time on that day to set the moment when these tickets become unavailable at the shop.",
        "daysBeforeEventEnds": "Days before the event ends",
        "daysBeforeEventStarts": "Days before the event starts",
        "dayTime": "Time on that day",
        "never": "Never"
      }
    },
    "profile": {
      "anonymous": "Anonymous",
      "firstName": "Nome",
      "lastName": "Cognome",
      "phoneNumber": "Numero di telefono",
      "mobileNumber": "Mobile number",
      "email": "Email",
      "language": "Language",
      "confirmEmail": "Confirm email",
      "zipcode": "CAP",
      "birthdate": "Data di nascita",
      "gender": "sesso",
      "ageGroups": "Gruppo d'età",
      "role": "Role",
      "m": "Maschio",
      "f": "Femmina",
      "o": "Altro",
      "lastPurchase": "Ultimo acquisto",
      "contact": "Informazioni contatto",
      "addressInformation": "Informazioni indirizzo",
      "basicInformation": "Informazioni base",
      "address": "Street name & house number",
      "addressShort": "Indirizzo",
      "city": "City",
      "country": "Country",
      "state": "State",
      "nft_wallet_address": "NFT wallet address",
      "gusuuid": "GUS uuid",
      "publicTicketEngineUrl": "Wallet link"
    },
    "event": {
      "shortName": "Short name",
      "seated": "Posti numerati",
      "nonSeated": "Posto unico",
      "livestream": "Livestream",
      "title": "Titolo",
      "subtitle": "Sottotitolo",
      "location": "Luogo dell'evento",
      "sublocation": "Posizione",
      "city": "Città",
      "country": "Paese",
      "address": "Indirizzo",
      "datesTitle": "Dates & Times",
      "doorsOpen": "Opening time",
      "startDate": "Date the event starts",
      "daysRunning": "Duration in days",
      "when": "Ora d'inizio",
      "ends": "Closing time on the final day",
      "endDateTime": "End date and time",
      "saleStart": "Data d'inizio delle vendite",
      "saleEnds": "Data di fine delle vendite",
      "url": "URL dell'evento",
      "slug": "URL personalizzato",
      "draft": "Bozza",
      "timezone": "Timezone where the event is taking place",
      "capacity": "Capienza"
    },
    "ticketStatus": {
      "sold": "Venduto",
      "returned": "Restituito",
      "resold": "Rivenduto",
      "forsale": "In vendita",
      "being-resold": "For sale & in basket",
      "created": "Creato",
      "invalidated": "Annullato",
      "executed": "Convalidato",
      "partialCheckedIn": "Checked-in",
      "inCart": "Nel carrello",
      "notForSale": "Non in vendita",
      "notExecuted": "Non convalidato",
      "unassigned": "Non assegnato",
      "blocked": "Bloccato",
      "blocks": "Blocchi",
      "assigned": "Assegnato",
      "external": "Esterno",
      "free": "Gratuito",
      "released": "Released",
      "transferred": "Reservation Claimed"
    },
    "orderStatus": {
      "all": "All",
      "created": "Created",
      "pending": "Pending",
      "canceled": "Canceled",
      "timeout": "Timeout",
      "completed": "Completed",
      "failed": "Failed"
    },
    "reservationStatus": {
      "label": "Stato",
      "all": "All",
      "confirmed": "Confirmed",
      "distribution_started": "Distribution started",
      "awaiting_confirmation": "Awaiting confirmation",
      "awaiting_distribution": "Awaiting distribution",
      "invalidated": "Invalidated",
      "checked_in": "Checked-in",
      "partially_checked_in": "Checked-in",
      "invited": "Invited",
      "expired": "Expired",
      "canceled": "Canceled",
      "completed": "Completed"
    },
    "reservationInvitationStatus": {
      "label": "Status",
      "invited": "Invited",
      "claimed": "Accepted",
      "revoked": "Revoked",
      "created": "Created"
    },
    "paymentStatus": {
      "pending": "Pending",
      "paid": "Paid",
      "duplicated": "Duplicated",
      "expired": "Expired",
      "failed": "Failed",
      "canceled": "Canceled"
    },
    "refundStatus": {
      "pending": "Pending",
      "success": "Success",
      "failed": "Failed"
    },
    "orderType": {
      "normal": "Online",
      "cashier": "Cashier"
    },
    "seat": {
      "rows": "Fila | File",
      "rowsAbbreviation": "Rw",
      "sections": "Sezione | Sezioni",
      "sectionsAbbreviation": "Sec",
      "subsections": "Sottosezione | Sottosezioni",
      "subsectionsAbbreviation": "Sub-s",
      "entrances": "Entrata | Entrate",
      "entrancesAbbreviation": "Ent",
      "ranks": "Classe | Classi",
      "tiers": "Livello | Livelli",
      "seats": "Posto a sedere | Posti a sedere",
      "seatsAbbreviation": "St",
      "category": "Categoria",
      "cashierSeating": {
        "auto": "Seated automatically",
        "manual": "Seated manually",
        "not_applicable": "Not applicable"
      }
    },
    "ticketTypes": {
      "upsell": "Vendita extra",
      "access": "Entrance"
    },
    "ticketType": "Biglietto",
    "eventOrganizer": "Organizzatore dell'evento",
    "tickets": "Biglietto | Biglietti",
    "types": "Type | Types",
    "totals": "Totale | Totali",
    "total": "Totale",
    "from": "A partire da",
    "until": "Fino a",
    "description": "Descrizione",
    "available": "Disponibile",
    "forSale": "In vendita",
    "locks": "Blocco | Blocchi",
    "guests": "Ospite | Ospiti",
    "upsells": "Vendita extra | Vendite extra",
    "variants": "Variante | Varianti",
    "combiTicket": "Biglietto combinato",
    "guestlist": "Lista ospite",
    "price": "Prezzo",
    "free": "Gratis",
    "status": "Stato",
    "lastStatus": "Last status",
    "onDate": "on {date}",
    "groups": "Gruppo | Gruppi",
    "notes": "Nota | Note",
    "sales": "Vendite",
    "checkIns": "Convalide",
    "seating": "Posti a sedere",
    "privileges": "Privileges",
    "accessPrivileges": "Entrance Privilege | Entrance Privileges",
    "ownedBy": "Owned by",
    "noResults": {
      "default": "No results found...",
      "orders": "No orders found...",
      "customers": "No customers found..."
    },
    "results": "results",
    "step": "Fase {count}",
    "amount": "Importo",
    "amountFinance": "Amount",
    "amountRefunded": "Amount refunded to {currency}",
    "pricePerTicket": "Price per ticket",
    "numberOfTickets": "Number of tickets",
    "subtotal": "Subtotal",
    "paidInFiat": "Paid in {currency}",
    "paidWithCredits": "Paid with Credits",
    "refundedToCredits": "Refunded to Credits",
    "dateCreated": "Date created",
    "dateUpdated": "Last updated",
    "totalDeducted": "Total deducted",
    "referral_id": "Referral ID",
    "primary": "Primary",
    "secondary": "Secondary",
    "unknown": "Unknown",
    "note": "Nota",
    "optional": "(opzionale)",
    "mandatory": "(mandatory)",
    "localTime": "Orario locale",
    "other": "Altro",
    "pools": "Tipologie",
    "availableTicketsTitle": "Available tickets",
    "availableTickets": "Available",
    "allocated": "Suddivisi",
    "blocks": "Blocchi",
    "refundType": {
      "name": "Refund method",
      "auto": "Automatic",
      "manual": "Manual",
      "credits": "Credits",
      "no_refund": "No refund"
    },
    "shops": "Negozi",
    "details": "Dettagli",
    "priceType": "Price type",
    "priceSelect": "Select a price",
    "customPrice": "Custom price",
    "globalPrice": "Global price",
    "totalPrice": "Total price",
    "example": "(esempio)",
    "users": "Users",
    "currency": "Currency",
    "paidEur": "Paid with {currency}",
    "paidCredits": "Paid with Credits",
    "refundedEur": "Refunded to {currency}",
    "refundedCredits": "Refunded to Credits",
    "grossRevenue": "Gross Revenue",
    "refunded": "Refunded",
    "inCurrency": "In {currency}",
    "toCredits": "To Credits",
    "market": "Market",
    "info": "Info",
    "paidWithCreditsPartially": "Partially paid with credits",
    "mandatoryField": "mandatory field",
    "collectibles": "Collectibles"
  },
  "validation": {
    "error": {
      "minFileSize": "La dimensione del file deve essere maggiore di {max}Kb.",
      "minValue": "Il valore dovrebbe essere maggiore di {min}",
      "maxValue": "Il valore dovrebbe essere minore di {max}",
      "maxDecimals": "You can use up to {precision} decimals",
      "validateImageDeminsons": "La risoluzione deve essere maggiore di {width}x{height}",
      "minDate": "Il {field} deve essere dopo {minVal}",
      "maxDate": "Il {field} data deve essere prima {maxVal}",
      "minOffset": "Il {field} deve essere prima {maxVal}",
      "maxOffset": "The {field} must be after {minVal}",
      "required": "Questo campo non può essere vuoto",
      "richTextMaxLength": "Non può essere più di {maxLength} caratteri",
      "cover": "Questa immagine non ha le corrette dimensioni",
      "maxSize": "Non dovrebbe superare {maxSize} kb di dimensioni",
      "fileType": "Non è il tipo di file corretto",
      "maxLength": "Il campo non può essere più lungo di {max}",
      "maxCharLength": "Il campo non può essere più lungo di {max} caratteri",
      "combinedMaxLength": "Ogni campo non può essere più lungo di {max} caratteri",
      "decimal": "Dovrebbe essere un numero valido",
      "alphaNum": "Dovrebbe essere un valore alfanumerico valido",
      "alphaNumDashUnderscore": "Dovrebbe contenere solo valori alfanumerici, '_', '-'",
      "numeric": "Dovrebbe essere un valore numerico",
      "phoneNumber": "Questo numero di telefono non è valido.  (Country codes are required, i.e \"+39\")",
      "sameAsNumber": "The phone numbers do not match, please re-enter.",
      "sameAs": "The {field} entered do not match, please re-enter.",
      "url": "Please enter a valid url (https://example.com).",
      "email": "Please enter a valid email address",
      "integer": "Please enter a whole number",
      "minLength": "The {field} must be at least {length} characters long.",
      "nonNumeric": "The {field} cannot contain only numeric characters.",
      "wrongPass": "Please enter the correct password.",
      "phoneNumberValidation": "Always include plus sign (+) and country code!",
      "isNotUnicode": "Please make sure to use no unicode characters"
    }
  },
  "views": {
    "reports": {
      "title": "Your exports",
      "nav": {
        "exports": "Exports",
        "sales": "Sales"
      },
      "exports": {
        "tabs": {
          "revenue": "Revenue",
          "summary": "Summary",
          "orders": "Orders",
          "attendees": "Attendees",
          "blocks": "Blocks",
          "advanced": "Advanced",
          "groups": "Groups"
        },
        "type": {
          "group_sizes_report": "Groups",
          "revenue_report": "Revenue",
          "summary_report": "Summary",
          "orders_report": "Orders",
          "attendees_report": "Attendees",
          "blocks_report": "Blocks"
        },
        "exportHistory": {
          "title": "Your latest exports",
          "created": "Created {date}",
          "generating": "Generating export...",
          "exportGenerationSuccess": "Export successfully generated"
        },
        "selectEventsAction": "Add or remove events",
        "action": "Generate export",
        "reportsGenerationFail": "Generating export failed, please retry...",
        "configurationError": "No events belonging to your organisation in selected gate found...",
        "limitError": "You can generate a report for a maximum of {max} events! In case you want to create bigger exports, please contact our support.",
        "selectedEvents": "{selectedEvents} / {maxEvents} event selected | {selectedEvents} / {maxEvents} events selected",
        "gateInfo": "Note: only events belonging to your organisation in the selected gate will be included in the export.",
        "selectOrg": "Select the organisation to create export for",
        "options": {
          "tickets": "Options: tickets & upsells",
          "events": "Options: for events & shops"
        },
        "advanced": {
          "eventSales": "All Ledger Transactions",
          "secondaryMarket": "Resale Market Transactions",
          "billingSummary": "Billing Summary"
        },
        "attendees": {
          "privacy-policy": "Attendees opted-in on privacy policy (if applicable)",
          "user-privacy": "Only attendees that accepted your privacy-policy",
          "brand-privacy": "Only attendees that accepted the privacy-policy of {brand}",
          "all": "Include all attendees",
          "optional": "Include optional attendees",
          "non-owners": "Include attendees that don’t own tickets anymore (resold, invalidated)",
          "invited": "Include attendees invited by ticket-holders in a group",
          "warning": "Note: By European law you are allowed to use the data of attendees who have not accepted your privacy-policy for service necessities only. This excludes marketing."
        },
        "merge": {
          "pricetype": "Merge same price type",
          "hideItems": "Hide items with empty values"
        },
        "groupBy": {
          "events": "Group by event",
          "shop": "Group by shop"
        },
        "desiredFormat": "Desired format",
        "format": {
          "xlsx": "Excel (xlsx)",
          "csv": "Comma separated (csv)"
        },
        "includeEvents": "Include the following events",
        "eventOptions": {
          "all-events": "All events",
          "specific-gate": "Events in specific gate",
          "specific-events": "Select specific events"
        },
        "periodInclude": "Select period when revenue is received",
        "periodOptions": {
          "specific-period": "Specific period",
          "all-time": "All time"
        },
        "selectEventsModal": {
          "title": "Select events to include in export",
          "yourEvents": "Your events",
          "added": "Events added",
          "placeholder": "Search events",
          "addEvent": "Add {count} event | Add {count} events",
          "removeEvent": "Remove {count} event | Remove {count} events",
          "info": "To select multiple events, you can press shift or control (command on Mac) and select, or click and drag."
        }
      }
    },
    "organizations": {
      "organization": {
        "title": "Organisation",
        "name": "Organisation Identity",
        "legalName": "Organisation Name",
        "legalNameDescription": "The official name of the organisation as it will be shown to customers.",
        "privacyPolicy": "Privacy policy text and/or file (optional)",
        "privacyPolicyFileDescription": "Overwrite the default text for your privacy policy opt-in",
        "privacyPolicyPlaceholder": "I want to be kept informed of events and promotions and agree with the privacy statement of {orgName}.",
        "termsConditions": "Terms & conditions text and/or file (mandatory for attendee)",
        "termsConditionsFileDescription": "Overwite the default text for your terms & conditions opt-in",
        "termsConditionsPlaceHolder": "I agree with the terms and conditions of {orgName}.",
        "localeSelectPlaceholder": "Select default language for e-mails etc.",
        "pspSelectPlaceholder": "Select a payment service provider.",
        "demo": {
          "label": "Demo mode",
          "description": "Create organisation for demo purposes only",
          "info": "A demo organisation is limited to dummy payments, and cannot be used to sell real tickets.",
          "badge": "Demo",
          "alert": "This organisation is set to demo mode. Real payments are disabled. Events created under it are only to be used for demo or testing purposes."
        },
        "miscellaneous": {
          "title": "Miscellaneous",
          "refund": "Refund to credits",
          "expiration": "Expiration period",
          "expirationInfo": "This period can be overridden based on the role."
        },
        "paymentInfo": {
          "title": "Payments",
          "psp": {
            "label": "Payment Service Provider",
            "dummyInfo": "Note: Only use for demos or testing. Real payments are not possible.",
            "selectInfo": "Note: The selected currency determines which payment service providers are available.",
            "lockedInfo": "Note: You cannot switch PSP after your first ticket has been sold.",
            "options": {
              "mollie": "Mollie",
              "stripe": "Stripe",
              "dummy": "Dummy",
              "ticketeer_payments": "PSP of {brand}"
            }
          },
          "apiKey": {
            "test_key": "You don't have permission to submit TEST API credentials",
            "label_apiKey_mollie": "Live API key",
            "label_apiKey_stripe": "Secret Key",
            "label_publicKey_stripe": "Publishable key",
            "label": "API key",
            "publicKey": "Public API key",
            "addInfo": "Add the API key provided by your payment service provider. When you set the API key correctly, approval is done immediately.",
            "existsInfo": "Note: You cannot change this API key after your first ticket has been sold.",
            "error": "This API key does not seem to be valid",
            "apiErrors": {
              "invalid_api_key": "Error: Invalid API key"
            }
          },
          "methods": {
            "title": "Payment methods",
            "overrideLabel": "Override payment methods",
            "overrideInfo": "The following payment methods are set according to your PSP account you selected. If you chose to override the payment methods, changes in your PSP account won’t reflect in these settings.",
            "overrideInfoStripe": "Important: The accepted payment methods need to exactly match your Stripe enabled payment methods, otherwise payments will not work! By default everything is enabled."
          }
        },
        "fileDescription": "PDF's only with a maximum filesize of 20mb",
        "previewFileLink": "View file",
        "usersAndRoles": {
          "navTitle": "Users & roles",
          "create": "Invite user",
          "active": "Active",
          "deactivated": "Deactivated",
          "search": "Search users",
          "activateUser": "Activate",
          "pendingInvitation": "{role} pending invitation",
          "expiredInvitation": "Invitation expired",
          "inviteUserModal": {
            "edit": "Edit user",
            "pending": "Pending invitation",
            "expired": "Expired invitation",
            "invite": "Invite user",
            "info": "An email will be sent to this email address to create an account or log-in and link the organisation to this user.",
            "emailAlreadyUsed": "This email address has already been invited to the dashboard. Please use a different email address.",
            "send": "Send invitation",
            "resend": "Resend invitation",
            "removeInvitation": "Remove invitation",
            "deactivate": "Deactivate"
          },
          "switchUserStateModal": {
            "activateTitle": "Activate user",
            "deactivateTitle": "Deactivate user",
            "description": "Are you sure you want to {action} {email}?"
          }
        },
        "attendeeInfo": {
          "title": "Attendee information",
          "description": "The following is the information attendees will have to fill in when buying tickets for your events. You can select if you want the information below to be mandatory, optional or hidden. The fields marked as always mandatory cannot be edited.",
          "states": {
            "always": "Always mandatory",
            "mandatory": "Mandatory",
            "optional": "Optional",
            "hide": "Hide"
          },
          "anonymize": {
            "button": "Remove account",
            "modal": {
              "title": "Are you sure?",
              "description": "Removing the account will result in the deletion of all data and tickets. It CANNOT be rolled back."
            }
          }
        },
        "floorplans": "Floorplans"
      },
      "placeholder": "Search organisations",
      "create": "Create organisation",
      "modal": {
        "title": "Create organisation",
        "selectCurrency": "Select currency"
      },
      "table": {
        "psp": "PSP",
        "edit": "Edit"
      }
    },
    "account": {
      "editTitle": "Edit account",
      "passwords": {
        "password": "Password | Passwords",
        "changePass": "Change password",
        "resetPass": "Reset password",
        "forgotPass": "Forgot password?",
        "currentPass": "Current password",
        "newPass": "New password",
        "confirmPass": "Confirm password",
        "apiErrors": {
          "weak_password": "Password can't be similar to your email, name or a weak passwords like \"admin1234\""
        },
        "passwordInfo": {
          "title": "We expect a strong password:",
          "minLength": "Minimum length of 9 characters and not just numbers",
          "similar": "Not similar to your email, or weak passwords like \"admin123\"."
        }
      },
      "login": {
        "title": "Login",
        "invalidCredentials": "Invalid credentials",
        "noAccount": "Don't have an account?",
        "username": "Username"
      },
      "signup": {
        "title": "Sign up",
        "hasAccount": "Already have an account?",
        "apiErrors": {
          "user_already_exists": "An account with this email already exists."
        }
      },
      "reset": {
        "description": "Enter your email address and we’ll send you a link to reset your password.",
        "requestedDescription": "If there is an account associated with this email address, you will receive a link to reset your password.",
        "expiredAlert": "This link has expired. You can request another password-reset.",
        "toLogin": "Go back to login"
      }
    },
    "invite": {
      "title": "Accept invitation",
      "expired": {
        "alert": "Your invitation is not valid anymore.",
        "info": "For more information or help, please contact {0}."
      },
      "noInvite": "This invite has already been accepted or doesn't exist. You can continue to go to dashboard.",
      "accept": {
        "title": "You have been invited to join: {org}",
        "inviteAccepted": "You now have access to: {org}",
        "info": "The invitation will be accepted under your current account: {email}. If you wish to accept using a different account, log in to this account first and then click the invitation link again."
      }
    },
    "attendees": {
      "attendee": {
        "edit": {
          "changeNumber": {
            "title": "Change number",
            "description": "You are about to change the account with mobile number {number}",
            "inputLabel": "New mobile number"
          },
          "confirmNumber": {
            "attendees": "attendees",
            "migrateConfirm": "If you want to migrate, please confirm the new mobile number.",
            "existsDescription": "There is already an account tied to this new mobile number. Are you sure this is correct?",
            "existsWithTickets": "This account has upcoming events, therefore you cannot migrate. Go back if you want to use a different mobile number.",
            "inputLabel": "Confirm the new mobile number"
          },
          "success": "Successfully updated mobile number",
          "error": "Error: Could not update mobile number"
        }
      }
    },
    "customers": {
      "title": "Your customers",
      "startSearch": "Search for customers across all your events by entering a (partial) name, email address or phone number. Easy peasy!",
      "loading": "Customer loading...",
      "groupsLoading": "Loading groups...",
      "tooltips": {
        "privilegesDescription": "The total number of entrance privileges allocated to a customer."
      },
      "tickets": {
        "ticketsTable": {
          "emptyText": "No tickets found, check orders for more information",
          "emptyTextShort": "No tickets found",
          "resoldBy": "by {customer}"
        }
      },
      "privileges": {
        "privilegesTable": {
          "emptyText": "No privileges found"
        }
      },
      "orders": {
        "ordersOverviewTitle": "Your orders"
      },
      "refunds": {
        "refundsTable": {
          "emptyText": "No refunds found"
        }
      },
      "customer": {
        "noName": "Unnamed customer",
        "noAddress": "No address",
        "edit": {
          "changeNumber": {
            "title": "Change number",
            "description": "You are about to change the account with mobile number {number}",
            "inputLabel": "New mobile number"
          },
          "confirmNumber": {
            "migrateInfo": "This mobile number can be changed. You can optionally check if this account already exists, by going to {customers}.",
            "customers": "customers",
            "migrateConfirm": "If you want to migrate, please confirm the new mobile number.",
            "existsDescription": "There is already an account tied to this new mobile number. Are you sure this is correct?",
            "existsWithTickets": "This account has upcoming events, therefore you cannot migrate. Go back if you want to use a different mobile number.",
            "inputLabel": "Confirm the new mobile number"
          },
          "success": "Successfully updated mobile number",
          "error": "Error: Could not update mobile number"
        }
      },
      "filters": {
        "pool": "Pool",
        "shop": "Negozio",
        "checkin": {
          "name": "Checked-in status",
          "fully": "Fully",
          "partially": "Partially",
          "not": "Not"
        }
      }
    },
    "events": {
      "create": {
        "title": "Crea evento",
        "new": "New event",
        "fromTemplate": "Choose template"
      },
      "title": "Your events",
      "titleWithOrganization": "Your events for {org}",
      "reports": {
        "title": "Your sales",
        "titleWithOrganization": "Your sales for {org}",
        "revenue": "Revenue",
        "revenueBoxTitle": "NET revenue this period",
        "ticketSoldBoxTitle": "Tickets sold this period",
        "grossRevenue": "GROSS revenue",
        "invalidated": "Invalidated",
        "refunded": "Refunded",
        "upsellSoldBoxTitle": "Upsells sold this period",
        "credits": {
          "title": "Credits",
          "circulating": "Circulating",
          "info": "The amount of credits you refunded which haven't expired or been used yet"
        }
      },
      "eventCreate": {
        "title": "Create new {type}",
        "eventDescription": "Create a brand new event from scratch! These steps will help you create the event that matches your needs perfectly. First, you choose the core type of event. You can edit all other settings afterwards without a problem, except for this core event type. Of course, you can always start over, this will be saved as a draft until you decide it's good to go. No pressure, you've got this.",
        "templateDescription": "Create a new template from scratch! These steps will guide you to set up the minimal requirements for an event that you can use as your own template. You can edit all settings afterwards, except for the initial type of event chosen in this first step.",
        "templateInfo": "Once you have your template, you can automatically generate multiple events with the same set-up. All settings in the template will be copied and updated for all events. ",
        "setEventType": "Type of event",
        "selectType": {
          "label": "Select the type of event you want to create"
        },
        "selectVenue": "Seleziona luogo",
        "eventDetails": "Dettagli evento",
        "ticketManagement": "Gestione dei biglietti",
        "modal": {
          "title": "Anteprima dell' evento creato",
          "content": "Il tuo nuovo evento è stato creato con successo ed è stato salvato sotto Bozza Eventi.<br><br>Puoi pubblicare il tuo evento immediatamente, o tornare alla dashboard per effettuare ulteriori modifiche prima di pubblicarlo.",
          "dashboardButton": "Torna alla dashboard",
          "publishButton": "Pubblica evento"
        }
      },
      "eventCopy": {
        "action": "Copia Evento",
        "description": "Tutte le impostazioni dell'evento saranno copiate, insieme ai posti bloccati.",
        "startDate": "Start Date"
      },
      "templateCopy": {
        "action": "Copy Template",
        "description": "All configurations of the template will be copied, along with the holds."
      },
      "event": {
        "savedAsDraft": {
          "title": "La bozza di evento è stata salvata",
          "draftInfo": "Il tuo evento è stato creato con successo e salvato come bozza. Puoi accedere e modificare il tuo evento nella dashboard sotto la tua Bozza Eventi.",
          "previewInfo": "Puoi vedere l'anteprima del tuo evento ora e pubblicarlo più tardi, oppure pubblicarlo in questo momento. Clicca OK per tornare indietro alla dashboard."
        },
        "demo": {
          "description": "Demo event: This is event has been created under a demo organisation. Real payments are disabled. Only use this event for demo or testing purposes."
        },
        "unlink": {
          "title": "This event is managed by a template.",
          "description": "Changes to shops, tickets, pools and other event settings can only be made and synced to all events at once in this event’s {0}.",
          "eventTemplate": "template",
          "shop": {
            "title": "This shop is managed by a template.",
            "description": "Changes to this shop, its tickets and groups can only be made and synced to all events at once in {0}.",
            "link": "this event's template shop"
          },
          "action": {
            "description": "If you want to only make changes in this event {0}",
            "unlink": "unlink from template."
          },
          "modal": {
            "title": "Are you sure you want to unlink this event?",
            "description": "When you unlink this event from its template, it will not be updated anymore when changes are made in this template and synced. You are able to manage it completely as a single event. This action cannot be undone!",
            "action": {
              "unlink": "unlink",
              "description": "Type {0} in the field below to confirm"
            },
            "unlinkEvent": "Unlink event"
          }
        },
        "manage": {
          "placeholders": {
            "title": "e.g. Maximum Music Festival",
            "subtitle": "e.g. 20th Aniversary",
            "shortName": "e.g. MaxMusic"
          },
          "shortNameExplanation": "Will be used for SMS notifications when issuing tickets through the dashboard",
          "generalTitle": "Gestisci i dettagli generali dell'evento",
          "additionalTitle": "Gestisci i dettagli aggiuntivi dell'evento",
          "advancedTitle": "Gestisci i dettagli avanzati dell'evento",
          "saveValidateAlert": "Alcuni campi obbligatori risultano mancanti o presentano dati non validi.<br>Per favore verifica gli errori sopra.",
          "marketing": "Marketing",
          "scannerPassword": "Password dello scanner",
          "scannerQr": "QR code",
          "about": {
            "label": "Dettagli evento",
            "description": "Descrivi il tuo evento, comunica linee guida extra ai tuoi visitatori e fornisci informazioni generali e link esterni.",
            "help": "Lo stile di base può essere aggiunto selezionando il testo che si desidera modellare."
          },
          "endDateSwitch": "This event ends after midnight on the next day",
          "endDateDescription": "If an event ends after midnight (e.g. 01:00) it technically ends a day later - early in the morning. Toggle this to communicate that this event actually ends on the previous day.",
          "templateStartDateInfo": "Uses the specified date of an event when it is generated.",
          "dateErrors": {
            "required": "All date and time fields are required. Please make sure to fill in all information.",
            "minOpen": "The opening time cannot be after the start time of the event.",
            "minOpenLivestream": "The opening time for livestream should be at least 15 min before the event start."
          },
          "coverImage": {
            "label": "Foto di copertina",
            "description": "Aggiungi una foto di alta qualità, che sarà mostrata con ritagli diversi a seconda di dove viene usata nell'applicazione. Prova ed evita testi o loghi nell'immagine.",
            "help": "Tipi di file PNG o JPG. Risoluzione minima 640x480. Dimensione massima del file di 10 MB."
          },
          "floorplanImage": {
            "label": "Mappa o pianta del luogo",
            "description": "Will be provided by the ticketeer in eps format.",
            "help": "Tipo di file SVG o PNG. La miglior risoluzione è 375 pixel di larghezza."
          },
          "orderSuccessLink": {
            "label": "Messaggio di notifica d'acquisto biglietti",
            "description": "Mostra un breve messaggio per notificare l'acquirente."
          },
          "orderSuccessEmail": {
            "label": "Marketing via email",
            "description": "Contenuti personalizzati per le email di conferma dei biglietti."
          },
          "saveAlert": {
            "title": "Salva le modifiche",
            "description": "Sei sicuro di voler salvare le modifiche che hai apportato a questo evento già pubblicato? I visitatori saranno in grado di vedere le modifiche direttamente."
          },
          "publishAlert": {
            "title": "Rendi pubblico un evento",
            "description": "Sei sicuro di voler pubblicare questo evento e renderlo disponibile pubblicamente? <br> Una volta che un evento è pubblicato <b>non potrai</b> tornare indietro e impostarlo come bozza. I biglietti però saranno resi disponibili solo a partire dalla data di vendita prevista.",
            "shopsTitle": "Your event and the following shops will become public",
            "cancel": "Not now",
            "confirm": "Publish now",
            "warning": "Publishing cannot be undone",
            "shop": "Shop",
            "defaultGate": "Default Gate",
            "opens": "Opens on",
            "immediately": "Immediately",
            "shopsFailed": "Failed to load shops, please try again",
            "tryAgain": "Try again",
            "publishError": "The event could not be published. Please make sure that the payment service provider configuration of your organisation is fully setup.",
            "orgSettings": "Go to settings for: {name}"
          },
          "livestream": {
            "typeSelect": {
              "label": "Select how you would like to configure your livestream (optional)",
              "internal": "Internal: Use your own embed codes",
              "external": "External: Use an external link to redirect to"
            },
            "videoEmbed": {
              "label": "Livestream video embed code (optional)",
              "placeholder": "<iframe src='https://player.vimeo.com/video/123456789/' frameborder=0 allow=autoplay; fullscreen allowfullscreen></iframe>"
            },
            "chatEmbed": {
              "label": "Livestream chat embed code (optional)",
              "placeholder": "<iframe src='https://vimeo.com/live-chat/123456789/' frameborder='0'></iframe>"
            },
            "externalUrl": {
              "label": "Livestream external URL (optional)",
              "placeholder": "https://livestream.com"
            }
          },
          "advanced": {
            "sharing": {
              "title": "Sharing",
              "label": "Ticket sharing between attendees (optional)",
              "strict": "Share via QR code",
              "strictInfo": "Attendees need to meet up in person and scan a QR code to share their tickets with each other",
              "easy": "Share via Link",
              "easyInfo": "Attendees can send a link to invite others to join",
              "disabled": "Not allowed",
              "disabledInfo": "Attendees can't share their tickets for this event"
            },
            "private": {
              "title": "Blockchain Visibility",
              "label": "It's possible to hide your event details on the blockchain. This includes event name, location, image and the shop URL",
              "checkbox": "Do not show event details on the blockchain"
            },
            "orderConfirmEmail": {
              "title": "Order confirmation email",
              "label": "Email address (optional)",
              "placeholder": "example{'@'}organiser.com",
              "info": "We'll send out an e-mail to this address with details on every order made."
            },
            "scanLeeway": {
              "title": "Offline scanning leeway",
              "label": "Set amount of time in minutes",
              "info": "Attendees can enter with a QR code that doesn't require an internet connection. This setting defines how long these offline QR codes are valid."
            }
          },
          "blockchain": {
            "publish": {
              "title": "Visibility",
              "label": "When you publish, your event & shop information will also be visible on the GET Protocol blockchain. You can (optionally) specify when that should happen:",
              "never": "Do not show event details on the blockchain",
              "public": "This event's information has already been made public on the blockchain. It is not reversible.",
              "defaultInfo": "By default this is set to the opening time of your default shop."
            },
            "contractAddress": {
              "title": "Event contract address",
              "placeholderDraft": "Your event contract address will be generated once the event is published.",
              "label": "Below is the event contract address for you to view and verify all transactions.",
              "placeholder": "Generating (refresh the page to update)"
            },
            "nft": {
              "title": "Default Collectible Artwork",
              "label": "Upload your default Collectible artwork here. This design will be used for all ticket's collectibles (unless specifically overwritten within each ticket's settings).",
              "guidelines_title": "General guide lines:",
              "line_1": "PNG, GIF or JPG file type",
              "line_2": "Portrait mode with aspect ratio of 3:4",
              "line_3": "10 MB max file size",
              "line_4": "You can <a href='{href}' download>our template</a> as a base",
              "note": "Note: If no artwork is provided, a default artwork is used by GET Protocol."
            }
          },
          "resale": {
            "title": "Resale Fees"
          },
          "previewModal": {
            "title": "Send preview email",
            "success": "Email has been sent successfully",
            "error": "An error occured sending the email"
          }
        },
        "reports": {
          "sales": {
            "turnover": "Fatturato",
            "capacity": "Capienza riempita",
            "ticketsSold": "Biglietti venduti",
            "capacityFilled": "Capacity filled",
            "capacitySold": "Capienza venduta",
            "tooltipTicketsSold": "Venduto",
            "availabilityTooltip": "Alcuni biglietti possono essere presenti in più tipologie",
            "percentageSold": "Percentuale venduta",
            "revenue": "Ricavo",
            "grossRevenue": "Ricavo lordo",
            "graph": {
              "range": {
                "placeholder": "Seleziona un periodo",
                "last_day": "Ultime 24 ore",
                "last_month": "Ultimi 31 giorni",
                "all_time": "Sempre"
              },
              "title": {
                "last_day": "Biglietti venduti nelle ultime 24 ore",
                "last_month": "Biglietti venduti negli ultimi 31 giorni",
                "all_time": "Biglietti venduti per tutto il periodo"
              }
            }
          },
          "demographics": {
            "avgOrderSize": "Quantità media di biglietti per ordine",
            "totalBuyers": "Quantità totale di acquirenti",
            "locations": "Posizioni"
          },
          "execution": {
            "checkedInPercentage": "Percentuale di Convalidati"
          }
        },
        "attendees": {
          "filters": {
            "checkin": {
              "label": "Filtra per check-in",
              "partialExecuted": "Check-in parziale",
              "allExecuted": "Check-in completo",
              "notExecuted": "Check-in mancante",
              "all": "Mostra tutti"
            },
            "pools": "Filtra per tipologia"
          },
          "guestlist": {
            "action": "Aggiungi ospiti",
            "addRow": "Aggiungi fila",
            "guestListName": "Nome lista ospiti",
            "createVariant": "Crea nuovo...",
            "selectTicket": "Seleziona biglietto",
            "selectVariant": "Seleziona lista ospiti",
            "optionsTitle": "Aggiungi a lista ospiti gratuita",
            "seating": "Seleziona i posti bloccati da utilizzare",
            "selectPool": "Seleziona quale gruppo di biglietti utilizzare",
            "confirmTitle": "Conferma lista ospiti"
          },
          "cashier": {
            "selectTickets": "Select tickets",
            "blockCategory": "Block category",
            "ticketsLeft": "Tickets left",
            "specifyAmount": "Please specify how many {ticketName} tickets you would like to add",
            "cart": "Cart ({count})",
            "emailField": "Send confirmation to (optional)",
            "notifyBySms": "Notify via SMS",
            "notifyByEmail": "Notify via email",
            "addTicket": "Add {ticketName} Ticket",
            "addTicketDescription": "From which <strong>availability</strong> do you want to select your tickets from?",
            "addTicketAssignment": "How would you like to assign the seats?",
            "addTicketHowMany": "How many <strong>{ticketName}</strong> tickets from <strong>{shopName}</strong> would you like to add?",
            "addToCart": "Add to cart",
            "emailTooltip": "If no email address is specified, no confirmation email will be send.",
            "ticketsLoading": "Retrieving available tickets, please wait...",
            "tickets": "Biglietti",
            "issueDigitallyCheck": "Send tickets to phone?",
            "yourOrder": "Your order {orderId} for {customerName}",
            "orderCreated": "Order {orderId} created",
            "issueMore": "Issue more tickets",
            "reserveMore": "Reserve more tickets",
            "bookPayment": "Book payment",
            "distributeTickets": "Distribute tickets",
            "checkinAll": "Check all tickets in now",
            "noTicketsSelected": "No tickets selected",
            "confirmGuestInfo": {
              "confirmTitle": "Confirm Tickets",
              "confirmMainMessage": "Tickets will be linked to the provided phone number(s) and can be accessed by the attendee via the ticket wallet.<br/>Attendees will receive an e-mail confirmation with the event & ticket info.",
              "total": "Total",
              "hasEmail": "Confirmation sent to: {email}",
              "noEmail": "No confirmation sent (no email address provided)"
            },
            "actionTooltip": "Tickets can only be issued when the event has been published",
            "action": "Vendi biglietti",
            "optionsTitle": "Vendi biglietti",
            "categories": {
              "title": "Seleziona una categoria di blocco",
              "description": "Di seguito sono indicati tutti i biglietti non assegnati. Per favore, seleziona la categoria da cui vorresti che il tuo biglietto fosse venduto. Se una categoria non ha abbastanza blocchi disponibili, puoi modificarli {0}",
              "categoryLink": "qui."
            },
            "payment": {
              "label": "Pagamento ricevuto da",
              "placeholder": "Metodo di pagamento"
            },
            "seatingMode": {
              "auto": "Assign seats automatically",
              "manual": "Pick seat(s) now",
              "autoseatInfo": "After event organiser finalises the seating"
            },
            "issue_now_modal": {
              "title": "What do you want to do next?",
              "choices": {
                "issue": "Issue tickets",
                "reservation": "Create reservation"
              }
            },
            "add_to_reservation_modal": {
              "title": "Add selection to reservation",
              "description": "You are about to add your selection to the reservation. Are you sure?"
            },
            "reservationForm": {
              "expiryDate": "Expiry Date",
              "details": "Details",
              "reservationName": "Reservation Name",
              "note": "Note",
              "contactDetails": "Contact information",
              "firstName": "First Name",
              "lastName": "Last Name",
              "mobileNumber": "Mobile Number",
              "email": "Email",
              "notePlaceholder": "E.g. Team Building Event"
            }
          },
          "blockCategories": {
            "notfound": "Categoria non trovata...",
            "createCategory": "Crea categoria",
            "createCategoryTitle": "Crea una nuova categoria",
            "deleteTooltip": "Possono essere rimosse solo le categorie vuote",
            "editCategory": "Modifica categoria",
            "categoryName": "Nome categoria",
            "categoryNamePlaceholder": "e.g. Tecnici",
            "categoryShortNamePlaceholder": "ABC (max. 3)",
            "categoryShortName": "Shortcode",
            "uniqueShortNameError": "Gli shortcodes devono essere unici",
            "uniqueNameError": "Una categoria con questo nome è già esistente",
            "ungrouped": "Non categorizzata",
            "ungroupedShort": "UNC",
            "selected": "{selected} su {total} selezionati",
            "unblocked": "Blocchi rimossi con successo",
            "moved": "Blocchi spostati con successo nella categoria",
            "printTickets": "Stampa / PDF",
            "movePrivs": "Sposta in categoria",
            "noCategory": "No categoria",
            "editNote": "Modifica nota",
            "addNote": "Add note | Add notes",
            "floorplanLabel": "Categorie di blocchi",
            "addPrivs": "Aggiungi blocco",
            "addPrivsModal": {
              "title": "Aggiungi nuovo blocco alla categoria \"{category}\"",
              "selectPool": "Seleziona tipologia",
              "addSeated": "Vuoi bloccare specifici posti? {0}",
              "addSeatedLink": "Vai alla piantina"
            },
            "categorySearch": "Seleziona una categoria...",
            "details": {
              "ticket": "Livello / biglietto",
              "filterClear": "Pulisci filtro",
              "filterTicket": {
                "empty": "Filtra su livello / biglietto",
                "selected": "Filtrato per {name}"
              },
              "filterSection": {
                "empty": "Filtra su sezione",
                "selected": "Filtrato per {name}"
              },
              "filterClaimed": {
                "empty": "Filtra su status",
                "selected": "Filtrato per convalidato"
              }
            },
            "list": {
              "fields": {
                "name": "Categoria"
              }
            },
            "editTicketNote": {
              "titleEdit": "Edit note(s)",
              "titleAdd": "Add note(s)",
              "multipleNotes": "You're editing multiple notes, this change will reflect on all the items you selected.",
              "placeholder": "Nota"
            },
            "unblockModal": {
              "title": "Rilascia blocco(blocchi)",
              "description": "I blocchi selezionati saranno rilasciati e messi in vendita.",
              "descriptionCheckedIn": "Per favore nota: i blocchi convalidati non possono essere rilasciati, saranno semplicemente non categorizzati."
            }
          },
          "externalCodes": {
            "title": "Biglietti esterni",
            "ticketInfo": {
              "ticketsImported": "Totale dei biglietti importati",
              "lastImport": "Ultima importazione",
              "ticketsCheckedIn": "Totale dei biglietti controllati"
            },
            "importCSV": {
              "title": "Importa biglietti esterni utilizzando csv",
              "description": "Accetta e registra biglietti esterni nello scanner importando i loro codici a barre tramite un file csv. La struttura del <strong>csv</strong> file può essere sia una colonna singola contenente il codice a barre o contenere la colonna denominata 'codice a barre' (senza virgolette).",
              "placeholder": "Scegli il file csv o trascinalo qui...",
              "deleteAndReplace": "Sostituisci biglietti esistenti. Biglietti non controllati saranno cancellati",
              "parsingError": "Codici a barre non trovati. Usa una colonna singola o nomina la colonna 'codice a barre' (senza virgolette)",
              "uploadFailInvalidCSV": "Il file fornito non è un csv valido",
              "uploadFailNoCodes": "Il file fornito non contiene codici all'interno",
              "uploadFail": "Il file csv non può essere importato",
              "uploadSuccess": "{nbTickets} Biglietti importati. Esempio di un codice a barre importato: {barcodeExample}",
              "fileTooBig": "Il file csv che stai provando ad importare è troppo grande. La dimensione massima è 6MB.",
              "emptyFile": "Il file csv che stai provando ad importare è vuoto"
            }
          },
          "attendee": {
            "invalidateSuccess": "I biglietti sono stati annullati con successo",
            "invalidationFail": "Annullamento fallito, per favore riprova",
            "invalidateTicketConfirm": {
              "manual": "Manual refund",
              "auto": "Automatic refund",
              "credits": "Refund as credits",
              "noRefund": "Invalidate without refund",
              "creditsExpiration": "Expiration date",
              "creditsConfirm": "These credits will expire on {0} after which they can not be used anymore",
              "titleWithoutRefund": "Conferma annullamento",
              "refundType": "Come vorresti rimborsare i seguenti biglietti?",
              "partialRefundInfos": "Inserisci l'importo che desideri detrarre per biglietto",
              "deduct": "To deduct",
              "fullRefund": "Rimborso completo",
              "partialRefund": "Rimborso parziale",
              "deducted": "Detratto",
              "subtitle": "Stai per annullare e rimborsare il seguente biglietto | Stai per annullare e rimborsare i seguenti biglietti:",
              "subtitleWithoutRefund": "Stai per annullare il seguente biglietto | Stai per annullare i seguenti biglietti:",
              "totalAmount": "Importo totale",
              "refundAmount": "Rimborso",
              "partialRefundErrorMessage": "L'importo è troppo alto",
              "toolTipText": "Se non selezioni quest'opzione, i biglietti saranno messi in vendita di nuovo",
              "selectBlock": "Seleziona la categoria di blocco",
              "blockCheckbox": "Blocca i biglietti che sono ancora disponibili",
              "feeMessage": "La commissione di servizio verrà addebitata a:",
              "summaryMessage": "The following ticket(s) will be invalidated.",
              "category": "The tickets are blocked on category {0}",
              "actionUndoneInfo": "Quest'azione non può essere annullata.",
              "extraConfirmModal": {
                "extraConfirmMessage": "L'importo del rimborso è {0}. Quest'azione non può essere annullata. Sei sicuro di voler annullare e rimborsare i biglietti selezionati?",
                "extraConfirmTitle": "Concludi annullamento e rimborso",
                "extraFinalizeMessage": {
                  "text": "Digita {0} nel campo sottostante per confermare",
                  "finalize": "CONCLUDI"
                }
              }
            },
            "invalidateTicketOptions": {
              "title": "Annulla e rimborsa i biglietti",
              "missingPaymentMethod": "Per favore seleziona un metodo di pagamento",
              "subtitle": "Seleziona se e come vuoi procedere al rimborso dei biglietti che stai per annullare:",
              "tooltip": "L'importo rimborsabile è minore dell'importo d'acquisto. Questo potrebbe essere dovuto a rimborsi parziali fatti in passato o all'utilizzo di diversi metodi di pagamento.",
              "automatic": {
                "title": "Annulla con rimborso automatico",
                "description": "Rimborsa automaticamente il partecipante con l'importo pagato per il biglietto. Seleziona nel prossimo step se la commissione di servizio verrà addebitata al partecipante o all'organizzatore."
              },
              "credits": {
                "title": "Refund as credits",
                "description": "Refund the amount and add these funds to account of the user to be redeemed later."
              },
              "manual": {
                "title": " Annulla con rimborso manuale",
                "description": "Rimborsa manualmente utilizzando un metodo di pagamento esterno e adegua i rapporti di conseguenza. Seleziona nel prossimo step se la commissione di servizio verrà addebitata al partecipante o all'organizzatore."
              },
              "noRefund": {
                "title": "Annulla senza rimborso",
                "description": "Annulla solamente il biglietto, mantieni nei resoconti l'importo originale pagato."
              }
            },
            "ticketBuyer": "acquirente",
            "noGender": "No genere",
            "noAge": "No età",
            "noZipcode": "No codice postale",
            "noCountry": "No paese",
            "noPhoneNumber": "No phone number",
            "noEmail": "No email",
            "noWalletLink": "No wallet link",
            "ticketOwner": "Possiede biglietti",
            "activeGroupMember": "Group member",
            "inactiveGroupMember": "Has left group",
            "customerTickets": "Tickets",
            "customerOrders": "Orders",
            "customerPayments": "Payments",
            "customerRefunds": "Refunds",
            "groupTickets": "Biglietti di gruppo",
            "share": {
              "action": "Condividi biglietti",
              "label": "Condividi tutti i biglietti con:",
              "description": "Esistente o nuovo account",
              "newUser": "Nuovo account",
              "anonymousUser": "Account anonimo",
              "alert": {
                "title": "Condividi biglietti",
                "description": "Sei sicuro di voler condividere biglietti con <strong>{from} e <strong>{to}</strong>?",
                "newUserNotice": "Un nuovo account verrà creato per il nuovo membro.",
                "userNotice": "I biglietti verranno condivisi con l'account esistente di: <strong>{name}</strong>."
              }
            },
            "remove": {
              "title": "Cancella biglietto | Cancella biglietti",
              "description": "Sei sicuro di voler cancellare questo biglietto? | Sei sicuro di voler cancellare questi biglietti?"
            },
            "checkin": {
              "title": "Check-in",
              "description": "Sei sicuro di voler registrare questi biglietti?",
              "combiTickets": "Scegli cosa vuoi registrare per i biglietti combinati:"
            },
            "undoCheckin": {
              "title": "Annulla check-in",
              "description": "Sei sicuro di voler annullare il check-in in questi biglietti?",
              "combiTickets": "Scegli cosa desideri annullare il check-in per i biglietti combinati:"
            },
            "email": {
              "title": "Email Partecipante",
              "error": "Questa email non è valida",
              "sendEmails": "Invia Email",
              "orderEmail": "Conferma pagamento",
              "seatingEmail": "Dettagli dei posti a sedere"
            },
            "convert": {
              "selectOption": "Seleziona opzioni di conversione",
              "convertTo": "Converti a",
              "selectPool": "Seleziona quale gruppo di biglietti usare",
              "selectKind": "Seleziona biglietto",
              "selectShop": "Select shop",
              "left": "Rimanenti: {amount}",
              "paymentMethod": "Soldi ricevuti da",
              "paymentOptions": "{method} o {free}",
              "sendEmail": "Invia email con conferma del cambio al cliente",
              "selectPayment": "Metodo di pagamento",
              "total": "Differenza di prezzo totale:",
              "new": "Crea nuovo",
              "confirmConversion": "Conferma conversione"
            },
            "ticketDetails": {
              "id": "ID",
              "price": "Price",
              "order": "Order",
              "orderDate": "Order date",
              "shop": "Shop",
              "ownedBy": "Owned by",
              "status": "Status",
              "resoldBy": "Resold by",
              "on": "On",
              "onDate": "on {date}",
              "sellerPrice": "Seller Price",
              "buyerPrice": "Buyer Price",
              "buyerFees": "Buyer Fees",
              "sellerFee": "Seller Fees",
              "invalidatedBy": "Invalidated by",
              "refundMethod": {
                "cash": "Cash",
                "pin": "Pin",
                "digital": "Digital",
                "other": "Other"
              },
              "fees": "Fees",
              "payOut": "Payout",
              "resoldTo": "Resold to",
              "ticketId": "Ticket ID",
              "putForSaleBy": "Put for sale by",
              "privileges": "Privileges",
              "boughtBy": "Bought by",
              "boughtFrom": "Bought from"
            }
          }
        },
        "seating": {
          "legend": "Legenda",
          "sectionList": "Sezioni",
          "editNote": "Modifica note",
          "standingSection": "Sezione in piedi",
          "capacity": "Capienza",
          "overview": "Panoramica",
          "notePlaceholder": "Aggiungi nota...",
          "notEnoughFree": "Non abbastanza libero",
          "changes": "Modifiche",
          "seatClaimed": "I biglietti verificati non possono essere modificati",
          "categoryInfo": "Seleziona prima una categoria dalla lista sottostante, se desideri assegnare dei posti a una specifica categoria di blocchi. In caso contrario, i posti selezionati non saranno classificati.",
          "assign": {
            "title": "Assegna ai partecipanti i posti",
            "showAssigned": "Mostra assegnato",
            "allTicketTypes": "Tutti i gruppi",
            "onlyMultipleTicketTypes": "Combinazione di gruppi multipli"
          },
          "solve": {
            "title": "Autoassegna i posti a tutti i partecipanti",
            "description": "Con l'auto assegnazione, a tutti i partecipanti saranno automaticamente assegnati i posti migliori disponibili a seconda della dimensione di gruppo all'interno di ogni classe. Vuoi raggruppare insieme possessori di biglietti specifici? Se lo desideri puoi inserire un gruppo di massimo 3 numeri di telefono.",
            "groupPlaceholder": "Numero di telefono",
            "addGroup": "Aggiungi gruppo",
            "success": "Successfully solved!"
          },
          "finalize": {
            "title": "Finalizza gli attuali posti a sedere",
            "description": "Sei sicuro? La finalizzazione non può essere cancellata, tutti i partecipanti riceveranno le informazioni sui loro posti a sedere tramite email e sms.",
            "instructions": "Digita {0} nel campo sottostante per confermare:",
            "success": "Successfully finalized!"
          },
          "clearSuccess": "Successfully cleared!"
        },
        "scanning": {
          "actions": {
            "create": "Crea un profilo",
            "edit": "Modifica Profilo",
            "cannotEdit": "Questo profilo non può essere modificato"
          },
          "creation": {
            "description": "Crea un profilo che, durante il caricamento nello scanner, consenta a tale scanner di effettuare il check-in dei ticket solo in base ai pool selezionati"
          },
          "description": "{poolAmount} pool può essere scansionato attraverso questo profilo di scansione. Scansiona il seguente codice QR per accedere. | {poolAmount} pools possono essere scansionati attraverso questo profilo di scansione. Scansiona il seguente codice QR per accedere.",
          "mainProfile": {
            "title": "Profilo principale",
            "description": "Questo è il profilo di scansione principale e tutti i pool possono essere scansionati attraverso questo profilo. Scansiona il seguente codice QR per accedere.",
            "warning": "Attenzione! Questo codice QR è specifico per questo evento. Se si desidera andare alle informazioni di registrazione dell'organizzazione globale {0}."
          }
        },
        "shop": {
          "deleteShopModalTitle": "Cancella questo negozio",
          "deleteShopMessage": "Sei sicuro di voler cancellare questo negozio?",
          "deleteShopTooltip": "Se desideri cancellare il negozio, prima rimuovi tutti i biglietti",
          "copyInfo": "Tutti i biglietti e il layout del negozio verranno copiati in un nuovo negozio. Clicca Conferma se desideri continuare.",
          "notfound": "Negozio non trovato...",
          "title": "Manage tickets",
          "description": "Add tickets or combi tickets to your event",
          "ticketModalTitle": "Seleziona un gruppo per creare un biglietto",
          "vat": "IVA",
          "baseTicket": "Biglietto base",
          "deleteHelper": "I biglietti possono essere rimossi solo se nessuno di questi è stato ancora venduto.<br>Puoi sempre scegliere di nascondere un biglietto.",
          "newTicket": "Nuovo biglietto",
          "price": "Prezzo totale",
          "tax": "IVA (incl.)",
          "breakdown": "Scomposizione del prezzo",
          "artwork": {
            "toggle": "Collectible Artwork",
            "error": "Your image could not be uploaded. Please try again later.",
            "title": "Upload your Collectible artwork for this ticket which will override any default Collectible artwork set under the event's Blockchain tab.<br><br>You can update this artwork at any point in the event lifecycle.",
            "template": "download template",
            "update": "Update art for NFT's already minted",
            "guidelines": {
              "title": "General guide lines",
              "line_1": "PNG, GIF or JPG filetype",
              "line_2": "Portrait mode with aspect ratio of 3:4",
              "line_3": "10 MB max file size",
              "line_4": "You can use <a href='{href}' download>our template</a> as a base"
            },
            "note": "Note: Unless you upload an image, your event NFT artwork will be used by default."
          },
          "availabilitySettings": "Availability settings",
          "advanced": "Impostazioni avanzate",
          "maxTicketsLabel": "Ticket limit",
          "putForSale": "Metti i biglietti in vendita",
          "from": "Disponibile da",
          "till": "Disponibile fino a",
          "defaultShopTooltip": "Use this shop by default when a user shares a link to this event.",
          "nameInfo": "Used in dashboard only",
          "ticketLimitInfo": "Maximum number of tickets a user can buy in this shop",
          "advancedEditPoolsLabel": "Imposta il numero di privilegi dal pool che l'utente riceve quando acquista questo ticket (massimo 20 per pool)",
          "maxAmount": "Importo massimo",
          "pages": {
            "deletePageModalTitle": "Cancella pagina",
            "deletePageMessage": "Tutti i biglietti in questa pagina verranno spostati nella prima pagina. Sei sicuro di voler eliminare questa pagina?",
            "actions": {
              "moveToPage": "Sposta nella pagina",
              "create": "Crea pagina",
              "edit": "Modifica pagina"
            }
          },
          "access": {
            "title": "Links & embed codes for shop - {name}",
            "linksTitle": "This shop is accessible through the following gates & links:",
            "description": "Gates can hold multiple shops across different events for the user to pick from. By default each shop has it's own single gate (link).",
            "draftAlert": "This event is not published yet. The gate(s) won't show this event until it's published.",
            "singleGate": "Single shop gate"
          },
          "availability": {
            "title": "Availability",
            "description": "Per impostazioni predefinite i biglietti sono disponibili <strong>{from}</strong> fino <strong>{ends}</strong>. La quantità massima che può essere ordinata è <strong>{amount}</strong>.",
            "immediately": "subito",
            "eventEnds": "the event ends",
            "relative": "{days} days before the event ends, at {time}",
            "absoluteStart": "da {date}",
            "startLabel": "Vendita dei biglietti inizia alle",
            "endLabel": "Vendita dei biglietti termina alle"
          },
          "accessControl": {
            "title": "Access to Shop",
            "description": "Access to the store will be provided once a Collection is found in the Customer's Wallet. Select Collection(s) that will be used to grant access to the Shop.",
            "linkLabel": "Link to OpenSea",
            "urlPlaceholder": "opensea.io/...",
            "collectionsListLabel": "Collections selected",
            "errors": {
              "collectionNotFound": "The collection was not found.",
              "invalidUrl": "This is not a valid OpenSea collection URL.",
              "duplicateUrl": "This collection is already added.",
              "wrongChain": "The chain where this collection is deployed is not supported. Currently, we only support Ethereum Mainnet, Polygon Mainnet and Polygon Mumbai Testnet.",
              "openseaError": "An error occurred while fetching the collection. Please try again later.",
              "invalidCollection": "This collection doesn't have an NFT contract."
            }
          },
          "empty": {
            "action": "Crea il primo biglietto",
            "title": "Crea biglietti"
          },
          "actions": {
            "createShop": "Crea negozio",
            "copyShop": "Copia negozio",
            "newShop": "Nuovo negozio",
            "editShop": "Modifica negozio",
            "createGroup": "Crea gruppo",
            "editGroup": "Modifica gruppo",
            "createNewPool": "Crea tipologia",
            "addTicket": "Crea biglietto",
            "addRegular": "Biglietto",
            "addCombi": "Biglietto combinato",
            "addUpsell": "Vendita extra",
            "addGuestVariant": "Lista ospiti",
            "addGroup": "Crea gruppo",
            "saveLayout": "Salva layout",
            "saveDraft": "Salva come bozza",
            "addField": "Aggiungi nuova riga",
            "saveTemplate": "Save template"
          },
          "group": {
            "ticketPlaceholder": "Trascina un biglietto qui per creare un gruppo",
            "collapseCheckbox": "Comprimi per impostazione predefinita",
            "messagePlaceholder": "Messaggio di gruppo da mostrare sulla lista dei biglietti...",
            "defaultTitle": "Nuovo gruppo"
          },
          "blocksHelper": {
            "title": "Vuoi modificare la quantità di blocchi? {0}",
            "link": "Vai a categorie di blocchi"
          },
          "gates": {
            "title": "This shop is accessible through the following gates (links)",
            "description": "Gates can hold multiple shops across different events for the user to pick from. By default, each has its own (single) gate.",
            "localGate": "This shop",
            "defaultGateError": "Each shop is required to have a default gate. Please set a default gate by clicking on one of the stars.",
            "defaultTooltip": "Use this gate by default when linking to this shop"
          }
        },
        "checkin": {
          "title": "Self check-in",
          "description": "Scan the QR code to check-in attendees",
          "waiting": "Waiting for QR code...",
          "AllClaimed": "All claimed",
          "checkin": "Check-in {amount} ticket(s)",
          "success": "{amount} ticket(s) checked in succesfully!",
          "errors": {
            "noPrivileges": "This QR code does not contains tickets",
            "wrongEvent": "The QR code belongs to a different event"
          }
        },
        "pools": {
          "title": "Tipologia",
          "addNew": "Crea tipologia",
          "notFound": "Tipologia non trovata...",
          "poolSelection": "Seleziona una tipologia o tipologie multiple per creare un biglietto. Per prodotti di vendite extra, usa la tipologia vendite extra. Se non trovi la tipologia che stai cercando, puoi",
          "createNewPool": "crearne una nuova.",
          "guestTicket": "Questo è un biglietto ospite",
          "toolTipEdititon": "Questa tipologia non può essere modificata",
          "poolModal": {
            "ticketPrivilege": "ticket-privileges",
            "privilege": "privilege",
            "entrance": "Entrance",
            "upsell-text": "Upsell",
            "create": "Crea nuova tipologia",
            "description": "A privilege pool is used to determine the total number of so called {ticketPrivileges} (or {privilege} in short), that can be issued. A privilege is of the type {Entrance} or {Upsell}. 1x capacity equals 1 privilege.",
            "update": "Modifica tipologia",
            "name": "Nome",
            "validFrom": "Valido dal",
            "validTo": "Valido per",
            "blocks": "Blocchi (sottratti dal totale)",
            "totalAmoutOfTickets": "Quantità totale di biglietti",
            "blockHelperText": "Puoi sempre modificare dopo la quantità di blocchi e categorie.",
            "poolNameHelperText": "For instance 'VIP' or 'T-Shirt'",
            "totalCapacityHelperText": "Maximum capacity to be issued",
            "upsell": "Usa questo per le vendite extra di prodotti. La disponibilità dei biglietti non è influenzata dalle vendite extra di prodotti.",
            "deleteMessage": "Sei sicuro di voler cancellare questa tipologia?",
            "deleteTitle": "Cancella",
            "privilegeType": "Privilege type",
            "tellMeMore": "Tell me more",
            "listItem1": "Use to grant access to an event (or livestream)",
            "listItem2": "Total amount of tickets a user can get of this type can be limited per shop ",
            "listItem3": "Use for additional services/products (like drinks/merchandise)",
            "listItem4": "Does not count towards the users' amount of tickets limited per shop"
          },
          "details": {
            "sold": "Venduto",
            "tickets": "Biglietti",
            "shop": "Negozio",
            "price": "Prezzo"
          }
        }
      }
    },
    "reservations": {
      "addTickets": "Add tickets",
      "ticketsCount": "Ticket ({count}) | Tickets ({count})",
      "unclaimedTicketCount": "Unclaimed ({count})",
      "claimedTicketCount": "Claimed ({count})",
      "reservationsOverviewTitle": "Your reservations",
      "details": {
        "editTitle": "Edit reservation details"
      },
      "cancel": {
        "cancelTitle": "Cancel reservation",
        "cancelDescription": "Are you sure you want to cancel the reservation? This cannot be undone.",
        "yesCancelReservation": "Yes, cancel reservation",
        "blockTickets": "Block tickets that are available again",
        "blockTicketsDescription": "If you don't select this option, tickets will be up for sale again"
      },
      "confirm": {
        "title": "Confirm tickets",
        "descriptions": {
          "paidConfirmAll": "Are you sure you want to confirm the sale of all tickets in this reservation? Once confirmed, you'll be able to distribute the tickets. Please specify the payment method:",
          "freeConfirmAll": "Are you sure you want to confirm the sale of all tickets in this reservation? Once confirmed, you'll be able to distribute the tickets.",
          "paidConfirmPartial": "Are you sure you want to confirm the sale of the selected tickets in this reservation? Once confirmed, you'll be able to distribute the tickets. Please specify the payment method:",
          "freeConfirmPartial": "Are you sure you want to confirm the sale of the selected in this reservation? Once confirmed, you'll be able to distribute the tickets."
        }
      },
      "release": {
        "action": "Release",
        "title": "Release tickets",
        "description": "Are you sure you want to release the selected tickets from the reservation?"
      },
      "distributionModal": {
        "title": "Distribute tickets ({count})",
        "description": "Fill the email addresses of people to whom you want to distribute the selected tickets.",
        "allToOne": "All tickets to one person",
        "invitationEmail": "Invitation email address",
        "confirmText": "Send invitations"
      },
      "distribution": {
        "title": "Distribute Tickets",
        "emails": {
          "title": "Distribute tickets to the following email addresses",
          "info": "You can also enter multiple entries separated by a space, comma, or semi-colon.",
          "duplicates": {
            "title": "Duplicates",
            "message": "The following duplicate emails were not added to the list: <strong>{list}</strong>"
          },
          "errors": {
            "exceedsMax": "{count} out of {max} people added. Remove {remove_count} entries or reduce the number of tickets to distribute per person.",
            "validation": "One or more added email addresses are not valid, please correct them!"
          },
          "entriesLeft": "{count} entries left"
        },
        "tickets": {
          "title": "Each entry will receive the following tickets",
          "ticketLeft": "ticket left | tickets left"
        },
        "send": {
          "title": "Send invitations",
          "summary": {
            "title": "Summary",
            "description": "Each invitation contains the following ticket(s):"
          },
          "extraMessage": {
            "title": "Personal message",
            "description": "If you want you can add a short extra message to the ticket invitation email."
          },
          "sendAction": "Send invitations",
          "success": "Successfully created and sent out invitations!",
          "errors": {
            "create": "Failed to create invitations!",
            "send": "Failed to send invitations"
          }
        },
        "resend": {
          "title": "Resend invitation",
          "areYouSure": "Are you sure want to resend this invite?",
          "description": "We'll send another invitation to \"{email}\"",
          "error": "Failed to send invitation!",
          "success": "Succesfully sent out invitation!"
        },
        "revoke": {
          "title": "Revoke invitation",
          "areYouSure": "Are you sure want to revoke this invite?",
          "description": "After revoking, the tickets will be available again in the reservation to distribute.",
          "error": "Failed to revoke invitation!",
          "success": "Succesfully revoked invitation!"
        }
      }
    },
    "scanning": {
      "selectOrganisation": "Seleziona un'organizzazione per poter vedere le informazioni di accesso alla scansione",
      "selectOrganisationMessage": "Seleziona un'organizzazione",
      "organisations": "Organizzazioni",
      "scannerInfo": "Utilizzare le informazioni seguenti per accedere allo scanner. Tieni presente che queste informazioni di accesso sono condivise all'interno dell'organizzazione e non sono specifiche per un evento.",
      "downloadInfo": "Scarica GET In app sul tuo smartphone:",
      "passwordInfo": "Usa la password o scansiona il codice QR per accedere",
      "noOrganisationSelected": "Non hai ancora selezionato un'organizzazione."
    },
    "navigation": {
      "eventPreview": {
        "label": "Anteprima della bozza di evento",
        "title": "Anteprima del tuo evento",
        "content": "Visualizza il tuo evento e acquista biglietti di prova per assicurarti che tutto sia impostato correttamente.<br><br>Questa anteprima è esattamente come i tuoi visitatori sperimenteranno il flusso, ad eccezione del pagamento che, nella modalità di antemprima, utilizza un fornitore di pagamento di prova.<br><br>I biglietti di prova rimarranno nel tuo account, ma non influiranno sulle vendite e statistiche effettive."
      },
      "eventUrlInfo": "Il tuo evento sarà disponibile su questo URL dopo essere stato pubblicato",
      "back": "Torna a tutti i miei eventi"
    },
    "prices": {
      "title": "Your prices",
      "addPrice": "Create price type",
      "editPrice": "Edit price",
      "editOverviewDescription": "The following events and tickets share this same price type. Editing this price type, will affect all of them. If you wish to continue click confirm.",
      "table": {
        "name": "Price type name",
        "amount": "Amount",
        "usedBy": "Used by"
      },
      "priceSelect": {
        "noAvailable": "There are no global price types available for this organisation."
      }
    },
    "gates": {
      "title": "Your gates",
      "open": "Open date",
      "close": "Close date",
      "slug": "Slug",
      "create": "Create gate",
      "gate": {
        "description": "This gate is accessible when the first shop opens <strong>{start}</strong> until the last event in this gate ends<strong>{ends}</strong>.",
        "descriptionPrequeue": "Prequeue is <strong>enabled</strong>.",
        "descriptionAccess": "Access links are <strong>enabled</strong>.",
        "gateLinks": {
          "title": "Link & embed codes of gate",
          "linkInfo": "Always use this link when sharing the URL - Do not copy it from the browser bar.",
          "link": "Direct link to this gate",
          "embed": "Embed code of this gate"
        },
        "accessLinks": {
          "nav": "Access links",
          "title": "Generate access links",
          "description": "Fill in the amount of access links you want and click Export as csv. The links below are <strong>example</strong> links.",
          "action": "Export as csv"
        }
      },
      "gateSelect": {
        "noAvailable": "There are no gates available for this organisation. You can create a new one."
      },
      "shops": {
        "pastLabel": "Show past events",
        "searchPlaceholder": "Search for events or shops...",
        "nonGateShopsTitle": "All events & shops",
        "gateShopsTitle": "Shops in this gate ({count})",
        "addToGate": "Add ({count}) shop | Add ({count}) shops",
        "removeFromGate": "Remove ({count}) shop | Remove ({count}) shops",
        "selectInfo": "To select multiple shops, you can press shift or control (command on Mac) and select, or click and drag."
      },
      "modal": {
        "create": "Create gate",
        "edit": "Edit gate",
        "optionalSlug": "Slug (optional)",
        "nameDescription": "This name is only used in dashboard as an identifier",
        "delete": {
          "title": "Delete gate",
          "description": "Are you sure you want to delete this gate? If yes, click confirm."
        },
        "display": {
          "title": "Display",
          "description": "Select how you want to display your events:",
          "eventList": "Show events in list",
          "calendar": "Show events in calendar",
          "override": "Override event information",
          "overrideDescription": "By default, this information is set automatically based on the events added to this gate."
        },
        "access": {
          "title": "Access",
          "accessLinks": "Requires access links",
          "accessLinksDescription": "This will make this gate accessible only for people with an access-link",
          "prequeue": "Enable prequeue",
          "prequeueDescription": "This allows users to register in the queue before the sale starts"
        }
      }
    },
    "templates": {
      "title": "Your templates",
      "createTemplate": "Create template",
      "selectTemplate": "Select template",
      "noAvailable": "No template available",
      "templateNotFound": "Template not found...",
      "status": {
        "syncing": "Syncing...",
        "unsynced": "Out of sync",
        "synced": "All synced"
      },
      "sync": {
        "title": "Sync template changes to upcoming events",
        "description": "You made changes to this template. In order to update all events using this template, you can sync your changes here. Keep in mind <b>all changes</b> made will be applied to <b>all upcoming events</b> created using this template.",
        "action": "Sync changes",
        "syncNow": "Sync now",
        "changesApplied": "Your changes will be applied to these {0} upcoming events",
        "changesApplying": "All {0} upcoming events are in sync!",
        "eventsSyncing": "Syncing of events in progress...",
        "SyncStarted": "Sync started on {date}",
        "lastSync": "Last synced on {date}",
        "errors": {
          "nothingToSync": "This template is already all synced",
          "beingSynced": "This template is already being synced"
        },
        "warningModal": {
          "description": "All changes in this template with be synced to all upcoming events managed by this template. There are a few rules that might be applied to avoid any errors:",
          "lowerCapacity": "If you have lowered the capacity of pools but event(s) already sold more tickets than the lowered capacity then the pool size for that specific event will be set to what is currently sold. For example: capacity changed from 100 to 50 - event already sold 60 - pool size for that specific event will be set to 60.",
          "removeTickets": "When you have removed tickets that are already sold for specific events, those tickets will not be removed, but hidden (in shops) instead.",
          "removeShop": "When you have removed a shop but specific events still have tickets in that specific shop then that shop will not be removed from that event but closed instead."
        }
      },
      "templateAlert": "Keep in mind! If you wish to make changes, all events related to this template will be updated, too.",
      "createFromTemplate": {
        "title": "Create event from template",
        "createInfo": "All template settings will be copied to this event.",
        "templateInfo": {
          "text": "You can create a new template {link}.",
          "link": "here"
        },
        "timeInfo": "Local time: {timezone}, {time}. The time of the event is configured from the template.",
        "errors": {
          "templateBeingSynced": "You cannot create an event from this template because it's currently being synced.",
          "templateNotSynced": "You cannot create an event from this template because it' not synced."
        }
      }
    },
    "schedules": {
      "title": "Your schedules",
      "create": {
        "title": "Create schedule"
      },
      "schedulePatterns": {
        "description": "The following templates will be used to generate multiple events for the period you specify.",
        "action": "Add template",
        "days": "Days",
        "exceptions": "Exceptions",
        "modal": {
          "addTemplate": "Select the template to add to the schedule",
          "edit": "Edit schedule pattern",
          "pattern": "On which days of the week do the events occur?",
          "exception": "Add exception date (optional)",
          "addException": "Add exception"
        }
      },
      "entries": {
        "startDate": "Startdate",
        "endDate": "Enddate",
        "description": "Generate new events from the templates by specifying the first and last day. Exception dates will be skipped.",
        "generate": "Generate events",
        "syncWarning": "Events can't be generated because one or more templates are out of sync! Please sync your templates first before generating new events.",
        "newItemStatus": "<strong>{patternName}</strong> events will be generated",
        "confirmDescription": "The following events will be generated and published:",
        "confirmContinue": "If you wish to continue click confirm.",
        "rangeAlert": "You can only generate maximum 1 year ahead at once. The enddate should be before {maxDate}.",
        "countAlert": "You are about to create {count} new events at once. Are you sure?",
        "tooltip": {
          "existing": "Existing",
          "generate": "To generate"
        }
      }
    },
    "cashier": {
      "filters": {
        "available": "Available",
        "blocks": "Blocks",
        "fromBlocks": "From blocks",
        "price": "Price",
        "type": "Type",
        "ticket": "Ticket",
        "upsell": "Upsell",
        "shop": "Shop",
        "pool": "Pool",
        "lockedShop": "You can only select tickets from {shopName} shop. To select a different shop you will have to empty your cart first.",
        "lockedShopReservation": "You can only select tickets from {shopName} shop. to select a different shop you will have to create additional reservation."
      },
      "abortAction": {
        "title": "Cancel order",
        "description": "Are you sure you want to cancel this order? All tickets in the cart will be removed and you will not be able to undo this action.",
        "confirm": "Yes, cancel this order"
      },
      "removeTickets": {
        "title": "Remove {ticketName} tickets",
        "description": "How many {ticketName} tickets do you want to remove?",
        "max": "max"
      },
      "removeSeatedTickets": "Which tickets do you want to remove?",
      "removeAll": {
        "title": "Remove all",
        "description": "Do you want to remove all tickets from the cart?",
        "confirm": "Remove all"
      },
      "removeOne": {
        "title": "Remove ticket",
        "description": "Are you sure you want to remove this ticket?",
        "confirm": "Remove ticket"
      },
      "confirmationModal": {
        "titleOrder": "Create order",
        "titleReservation": "Create reservation",
        "description": {
          "detailsOnly": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will not receive any confirmation.",
          "detailsAndSMS": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive a confirmation of the purchase via SMS.",
          "detailsAndEmail": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive an email confirmation with the event & ticket information.",
          "detailsSMSAndEmail": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive a confirmation of the purchase via SMS and an email confirmation with the event & ticket information.",
          "onlyEmail": "Tickets are not linked to the customers account because no phone number was provided. If you want to give the customer the tickets, you need to print the order. The customer will not receive any confirmation.",
          "onlySMS": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive a confirmation of the purchase via SMS.",
          "onlyNumber": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will not receive any confirmation."
        },
        "notifySMS": "Notify via SMS",
        "notifyEmail": "Notify via email",
        "reservation": {
          "name": "Reservation name",
          "note": "Note",
          "expiry": "Expiry"
        }
      },
      "complete": "Complete order"
    }
  },
  "errors": {
    "not-found": {
      "title": "Can’t access this page",
      "description": "You either do not have permission to view this page or it simply does not exist.",
      "contactSupport": "If you believe this to be an error, contact the organisation you trying to access, or {0} for help.",
      "link": "contact support"
    }
  },
  "filter": {
    "filterBy": "Filter by {name}",
    "operators": {
      "e": "Equals to",
      "lte": "Less than or equal to",
      "gte": "Greater than or equal to",
      "lt": "Less than",
      "gt": "Greater than"
    },
    "timeOperators": {
      "last": "Is in the last",
      "equal": "Is equal to",
      "between": "Is between",
      "after": "Is after",
      "onafter": "Is on or after",
      "before": "Is before",
      "onbefore": "Is before or on"
    },
    "times": {
      "days": "Days",
      "weeks": "Weeks",
      "months": "Months",
      "years": "Years"
    },
    "aria": {
      "open": "Open filter menu",
      "close": "Close filter menu",
      "remove": "Remove filter",
      "add": "Add {name} filter",
      "openAdd": "Open add filter menu",
      "clearAll": "Clear all filters"
    },
    "addFilter": "Add filter",
    "clearAll": "Clear all",
    "select": "Select",
    "selectAll": "Select all",
    "between": "And"
  },
  "resaleFeeConfig": {
    "sellerTitle": "Seller fees",
    "buyerTitle": "Buyer fees",
    "fixedTitle": "Fixed fees",
    "fixedLabel": "Fixed fee per ticket",
    "percTitle": "Percent fees",
    "percLabel": "Percent fee per ticket",
    "totalLabel": "Total fee",
    "getFeeLabel": "Base - GET International",
    "partnerFeeLabel": "Ticketeer fee",
    "override": {
      "label": "Ticketeer fee override",
      "confirm": {
        "title": "Override Ticketeer default fees",
        "description": "Are you sure you want to override the resale market fees for this organization? Any changes to the resale market fee plans will only apply for newly published events. Events that are currently on sale will retain the current fee configuration."
      }
    }
  }
}
